import { IndividualFeature, Resource as ResourceInterface } from '@/types'

export function resourceFactory(
  feature: IndividualFeature
): ResourceInterface | null {
  if (!feature.resource && !feature.resourceLink) {
    return null
  }
  return {
    resource: feature.resource,
    resourceLink: feature.resourceLink,
    title:
      feature.resourceTitle ||
      (feature.resource ? feature.resource.filename : 'Open File'),
    svgIcon: feature.svgIcon
  }
}
