<template>
  <div>
    <div container>
      <div grid="row wrap stretch">
        <div
          v-for="(post, i) in posts" 
          :key="i" 
          column="xs-12 s-12 m-6 l-4"
          class="_mbs _mts">
          <post-card
            :post="post"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackPost, Boilerplate } from '@/types'
  import PostCard from '@/components/sections/FeaturedPosts/PostCard.vue'

  export default defineComponent({
    name: 'BlogPostCards',
    components: { PostCard },
    props: {
      section: {
        type: Object as () => Boilerplate,
        required: false
      },
      posts: {
        type: Array as () => ContentStackPost[],
        required: true
      }
    },
    provide() {
      return {
        colorOptions: this.section ? this.section.colorOptions : null
      }
    }
  })
</script>
