import { defineComponent } from 'vue'
import {
  Boilerplate,
  ContentStackEntryPayload,
  ContentStackCategoryPage,
  ContentStackPost,
  MicrositeReferences
} from '@/types'
import { getPostsByCategory } from '@/ts/contentstack'

export interface FeaturedPostsSection extends Boilerplate {
  manualEntries: Record<string, any>[];
  featuredPosts: {
    choice: (
      | {
          category: {
            numberToDisplay: number;
            displayCategorySelectDropdown: boolean;
            reference: Record<string, unknown>[];
          };
        }
      | {
          posts: {
            reference: Record<string, unknown>[];
          };
        }
      | { manualEntries: { foo: 'bar' } }
    )[];
  };
}

export interface SiftedPosts {
  referencedPostList: boolean;
  posts: Record<string, any>[] | ContentStackPost[];
}

export function filterPagetypeByReferences(
  micrositeReferences?: MicrositeReferences,
  clientID?: string
): boolean {
  let hideLink = false
  if (clientID && micrositeReferences) {
    if (
      micrositeReferences.omitFrom &&
      micrositeReferences.omitFrom.length &&
      micrositeReferences.omitFrom.find(ref => ref.clientId === clientID)
    ) {
      hideLink = true
    }
    if (
      micrositeReferences.appearOnly &&
      micrositeReferences.appearOnly.length &&
      !micrositeReferences.appearOnly.find(ref => ref.clientId === clientID)
    ) {
      hideLink = true
    }
  }
  return !hideLink
}

import CategorySelector from '@/components/sections/FeaturedPosts/CategorySelector.vue'

export const postsState = defineComponent({
  components: { CategorySelector },
  props: {
    section: {
      type: Object as () => FeaturedPostsSection,
      required: true
    }
  },
  data() {
    return {
      chosenFeaturedPosts: this.section.featuredPosts.choice[0],
      postsByCat: [] as Record<string, any>[],
      allFetched: false,
      loaded: false,
      currentLang: this.$route.params.lang as string,
      clientID: (this.$route.params.clientID as string) || '',
      pageSlug:
        `${
          this.$route.fullPath
            .replace(`/${this.$route.params.clientID}`, '')
            .replace(`/${this.$route.params.lang}`, '')
            .replace('/category', '')
            .split('?')[0]
        }` || ('/' as string),
      fetching: false,
      preventFetchByCat: false,
      userSelectedCategory: null as ContentStackCategoryPage | null,
      noPostsMessage: this.$store.state.globals.globalLabels.noPostsForSelectedCategory,
      tick: 0
    }
  },
  computed: {
    postsByCategory(): boolean {
      return !!(this.chosenFeaturedPosts as any).category
    },
    postsByReferenceList(): boolean {
      return !!(this.chosenFeaturedPosts as any).posts
    },
    postsByManualEntries(): boolean {
      return !!(this.chosenFeaturedPosts as any).manualEntries
    },
    postsToDisplay(): SiftedPosts {
      const postsToDsiplay = {
        posts: [] as Record<string, any>[],
        referencedPostList: true
      }
      if (this.postsByCategory) {
        postsToDsiplay.posts = this.postsByCat
      } else if (this.postsByReferenceList) {
        const referencedPosts =
          (this.chosenFeaturedPosts as any).posts.reference || []
        postsToDsiplay.posts = referencedPosts.filter(post =>
          filterPagetypeByReferences(post.micrositeReferences, this.clientID)
        )
      } else if (this.postsByManualEntries) {
        postsToDsiplay.posts = this.section.manualEntries
        postsToDsiplay.referencedPostList = false
      }

      if (this.postsByCategory || this.postsByReferenceList) {
        // kill referenced posts that are no longer pblished or no longer exist
        postsToDsiplay.posts = postsToDsiplay.posts.filter(post => !!post.url)
      }

      return postsToDsiplay
    },
    postsExist(): boolean {
      return this.postsToDisplay && this.postsToDisplay.posts && this.postsToDisplay.posts.length !== 0
    },
    referencedCategory(): null | ContentStackCategoryPage {
      if (!this.postsByCategory) return null
      const catRef = (this.chosenFeaturedPosts as any).category.reference[0]
      return this.userSelectedCategory || catRef
    },
    numCategoryPostsToDisplay(): number {
      const catRef = (this.chosenFeaturedPosts as any).category
      return catRef ? catRef.numberToDisplay : 0
    },
    showCategorySelect(): boolean {
      const renderCategoryDropdown = (this.chosenFeaturedPosts as any).category && (this.chosenFeaturedPosts as any).category.displayCategorySelectDropdown
      return !!this.referencedCategory && !!this.postsByCategory && renderCategoryDropdown
    }
  },
  watch: {
    userSelectedCategory: {
      async handler(newVal) {
        this.fetching = true
        this.tick++

        if (this.showCategorySelect) {
         
            const postsByCat = await this.getPostsByPage(1, newVal)
            if (postsByCat) {
              this.allFetched = true
            }
        }
          
        this.fetching = false
      },
      deep: true
    }
  },
  methods: {
    setPostsPayload(newCategory?: ContentStackCategoryPage): ContentStackEntryPayload {
      const currentCategory = newCategory || this.referencedCategory || ({} as any)
      return {
        clientID: this.clientID,
        contentTypeUid: 'posts',
        lang: this.currentLang,
        jsonRtePath: ['full_description'],
        categoryUrl: currentCategory.url || this.pageSlug,
        skip: 0,
        limit: this.numCategoryPostsToDisplay
      }
    },
    async getPostsByPage(currentPage: number, newCategory?: ContentStackCategoryPage): Promise<boolean> {
      const postsPayload = this.setPostsPayload(newCategory)
      postsPayload.skip = (currentPage - 1) * this.numCategoryPostsToDisplay
      this.fetching = true

      const postsByCat = await getPostsByCategory(postsPayload)
      if (postsByCat) {
        this.postsByCat = postsByCat.posts
      }

      this.fetching = false
      return true
    },
    omitCategory(micrositeReferences: MicrositeReferences): boolean {
      return !filterPagetypeByReferences(micrositeReferences, this.clientID)
    },
    updateSelectedCategory(category: ContentStackCategoryPage): void {
      this.userSelectedCategory = category
    }
  },
  provide() {
    return {
      colorOptions: this.section.colorOptions
    }
  },
  async created() {
    if (!this.postsByCategory || !this.referencedCategory) return
    const currentCategory = this.referencedCategory

    this.preventFetchByCat = this.omitCategory(
      currentCategory.micrositeReferences as MicrositeReferences
    )

    if (!this.preventFetchByCat) {
      const postsByCat = await this.getPostsByPage(1)
      if (postsByCat) {
        this.allFetched = true
      }
    }

    this.loaded = true
  }
})
