<template>
  <header class="main-nav">
    <a
      class="hidden-link"
      href="#app-body"
      @click.prevent="navigateToHash('#app-body')"
    >skip to page content</a
    >
    <desktop-nav
      :color-override="colorOverride"
      :nav-items="navItems"/>
    <mobile-nav
      :color-override="colorOverride"
      :nav-items="navItems"
    />
  </header>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import DesktopNav from '@molecules/Navigation/NavigationDesktop.vue'
  import MobileNav from '@molecules/Navigation/NavigationMobile.vue'
  import {
    MainNavigationItem,
    MainNavigationGroup,
    MainNavigationItemExternal
  } from '@/types'
  import {
    navigateToHash,
    setExternalLinkAriaLabels
  } from '@/ts/dom-helper.methods'

  export default defineComponent({
    name: 'appHeader' as string,
    components: {
      DesktopNav,
      MobileNav,
    },
    props: {
      navItems: {
        type: Array as () => (MainNavigationItem | MainNavigationGroup | MainNavigationItemExternal)[],
        required: false
      },
    },
    computed: {
      colorOverride(): string | undefined {
        const globalColorOverrideField = this.$store.state.globals.navThemeColorOverride
        if (globalColorOverrideField && globalColorOverrideField.length) {
          const colorOverrideObject = globalColorOverrideField[0]
          return colorOverrideObject.colorHex as string 
        } else {
          return undefined
        }
      },
    },
    methods: {
      navigateToHash
    },
    mounted() {
      setExternalLinkAriaLabels(this.$el)
      const el = this.$el as HTMLElement
      el.setAttribute('tabindex', '0')
      el.focus()
      el.blur()
      el.removeAttribute('tabindex')
    }
  })
</script>
<style scoped>
.main-nav {
  outline: none !important;
}
</style>
