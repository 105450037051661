<template>
  <localized-router-link
    v-if="post.link"
    class="curated-feature"
    :to="post.link.href"
    :style="globalTheme.color"
    :class="{first: first}"
  >
    <div
      v-if="post.featuredImage"
      class="image-wrap">
      <p
        v-if="first && (category || post.postType && post.postType.postType)"
        class="post-type-label"
        :style="[globalTheme.backgroundColor]">{{category || post.postType.postType}}</p>
      <app-image
        :image="post.featuredImage"
        aria-hidden/>
    </div>
    <div
      class="content"
      :class="{ 'no-image': !post.featuredImage }">
      <p
        v-if="post.link.title"
        class="title">{{ post.link.title }}</p>
      <p
        v-if="post.blurb"
        class="read-more"
        :style="globalTheme.colorText">
        {{post.blurb}}
      </p>
      <hr
        v-if="!first"/>
    </div>
  </localized-router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    ContentStackLink,
    ContentStackPost,
    PostType
  } from '@/types'

  export interface CuratedFeatureProps {
    postType: PostType;
    blurb?: string;
    featuredImage?: ContentStackFile;
    link: ContentStackLink;
  }

  export function curatedFeatureFactory(post: ContentStackPost, readMoreLabel: string): CuratedFeatureProps {
    return { 
      postType: post.postType,
      blurb: readMoreLabel,
      featuredImage: post.featuredThumbnail || post.featuredImage,
      link: {
        title: post.title,
        href: `/post${post.url}`
      }
    }
  }

  export default defineComponent({
    name: 'CuratedFeature',
    props: {
      category: {
        type: String,
        required: false
      },
      first: {
        type: Boolean,
        default: false
      },
      post: {
        type: Object as () => CuratedFeatureProps,
        required: false
      }
    }
  })
</script>
