<template>
  <th :style="[globalTheme.backgroundColor, globalTheme.color]">
    <slot></slot>
  </th>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Cell',
    props: {
      themeColorOverride: {
        required: false,
        type: Array as ()=> Record<string, unknown>[]
      }
    },
    computed: {
      colorOverride(): string | undefined | unknown {
        const colorOverrideObject =
          this.themeColorOverride &&
          this.themeColorOverride.length
            ? this.themeColorOverride[0]
            : {}
        return colorOverrideObject.colorHex as string | undefined
      }
    }
  })
</script>
