<template>
  <section class="member-demo-module rich-text-section">
    <div container>
      <div grid="row wrap top justify-between">
        <div
          :column="
            filteredRelatedPosts.length ? 'xs-12 s-12 m-8' : 'xs-12 s-12'
          "
        >

          <!-- Main Content -->
          <div
            v-if="section.title || section.content"
            class="intro-content">
            <h2
              v-if="section.title"
              :style="globalTheme.color"
              class="_mbs">
              {{ section.title }}
            </h2>
            <div
              v-if="section.content"
              class="copy-wrap">
              <rich-text
                :html="section.content"
                :theme-color-override="
                  section.colorOptions
                    ? section.colorOptions.themeColorOverride
                    : null
                "
                class="not-themed"
              ></rich-text>
            </div>
          </div>

          <!-- Accordion -->
          <div
            v-if="section.accordionDrawers && section.accordionDrawers.length"
            class="accodion _mtm"
          >
            <app-collapsible-section
              v-for="(drawer, i) in section.accordionDrawers"
              :key="i"
              collapsed
              :background-as-offwhite="section.backgroundAsOffwhite"
              :content-aria-label="drawer.title"
            >
              <template v-slot:header-text>
                <p
                  class="title .h2"
                  :style="globalTheme.color">
                  {{ drawer.title }}
                </p>
              </template>
              <rich-text :html="drawer.content" />
            </app-collapsible-section>
          </div>

          <!-- CTA Block -->
          <div
            v-if="section.buttonLink && section.buttonLink.href"
            class="cta-wrap _mtm"
            :class="{ 'center-cra': !filteredRelatedPosts.length }"
          >
            <app-button-link
              :button="section.buttonLink"
              :style="globalTheme.button"
            />
          </div>
        </div>

        <!-- sidebar -->
        <div
          v-if="filteredRelatedPosts.length"
          column="xs-12 s-12 m-4">
          <div
            class="related-post _ps"
            :style="setBackgroundColor(section)">
            <related-post
              v-for="(post, i) in filteredRelatedPosts"
              :key="i"
              :post="post"
              :theme-color-override="section.sidebar.themeColorOverride"
            />
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate, ContentStackPost, MicrositeReferences, ContentStackLink } from '@/types'

  import { filterPagetypeByReferences } from '@/mixins/FeaturedPostsModuleState'

  export interface SidebarProps {
    relatedPosts?: ContentStackPost[]
    themeColorOverride?: Record<string, unknown>[]
  }

  export interface DrawerProps {
    title?: string
    content?: string
  }

  export interface SimpleRichTextProps extends Boilerplate {
    sidebar?: SidebarProps
    accordionDrawers?: DrawerProps[]
    buttonLink?: ContentStackLink
  }

  import {
    LargeCardProps,
    largeCardFactory,
  } from '@/components/sections/FeaturedPosts/LargeCard.vue'

  import RelatedPost from '@/components/sections/FeaturedPosts/RelatedPost.vue'

  export default defineComponent({
    name: 'simple-rich-text',
    components: { RelatedPost },
    props: {
      section: {
        required: true,
        type: Object as () => SimpleRichTextProps,
      },
    },
    data() {
      return {
        readMoreLabel: this.$store.state.globals.globalLabels.readMore,
      }
    },
    computed: {
      filteredRelatedPosts(): LargeCardProps[] {
        const allPosts = this.section.sidebar
          ? this.section.sidebar.relatedPosts
          : []
        const clientID = (this.$route.params.clientID as string) || ''
        return (allPosts || [])
          .filter((post) =>
            filterPagetypeByReferences(
              post.micrositeReferences as MicrositeReferences,
              clientID
            )
          )
          .filter((post) => post.url !== `/${this.$route.params.post}`)
          .map((refPost) => largeCardFactory(refPost, this.readMoreLabel))
      },
    },
    methods: {
      setBackgroundColor(section): { backgroundColor: string } {
        return {
          backgroundColor:
            section.colorOptions && section.colorOptions.backgroundAsOffwhite
              ? '#fff'
              : '#F8F8F8',
        }
      },
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions,
      }
    },
  })
</script>
