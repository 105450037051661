<template>
  <template v-if="!page && loaded">
    <four-o-four />
  </template>
  <template v-else-if="loaded">
    <div id="app-body">
      <place-posts
        v-if="!postsPlacedByLoop"/>
      <the-loop
        v-if="page && page.loopableSections.length"
        :loaded="loaded"
        :loopable-sections="page.loopableSections"
      />
    </div>
  </template>
  <app-loader v-else/>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import TheLoop from '@/components/TheLoop.vue'
  import FourOFour from '@/views/404.vue'
  import PlacePosts from '@/components/sections/PlacePosts.vue'

  import { ContentStackPagePayload } from '@/types'
  import { getPage } from '@/ts/contentstack'
  import { AdobeAnalytics } from '@/mixins/AdobeAnalytics'

  export default defineComponent({
    name: 'ArchivePage',
    components: {
      FourOFour,
      TheLoop,
      PlacePosts
    },
    mixins: [ AdobeAnalytics ],
    data() {
      return {
        page: null as Record<string, any> | null,
        loaded: false,
        currentLang: this.$route.params.lang as string,
        clientID:  this.$route.params.clientID as string,
        pageSlug:
          `${
            this.$route.fullPath
              .replace(`/${this.$route.params.clientID}`, '')
              .replace(`/${this.$route.params.lang}`, '')
              .replace('/category', '')
              .split('?')[0]
          }` || ('/' as string),
      }
    },
    computed: {
      postsPlacedByLoop(): boolean {
        if (!this.page) return false
        return !!this.page.loopableSections.find(section => section.contentTypeUid === 'place_posts')
      }
    },
    async created() {
      const stackPayload: ContentStackPagePayload = {
        pageType: 'categories',
        lang: this.currentLang,
        clientID: this.clientID,
        pageSlug: this.pageSlug,
        uid: ''
      }
      const globals = this.$store.state.globals
      const globalsUID = globals.uid
      stackPayload.uid = globalsUID
      const page = await getPage(stackPayload)
      this.page = page
      this.loaded = true

      if (page) {
        this.$store.commit('setCurrentRoute', this.$route.path)
        this.$store.commit('setCurrentPage', page)
        this.sendItToAdobe(page.title)
      }
    }
  })
</script>
