<template>
  <div class="copy-wrap lists">
    <div grid="row wrap stretch">
      <div
        v-for="(listColumn, j) in listColumns"
        :key="j"
        :column="`xs-12 s-6 m-6 l-${generateColumnWidth(listColumns.length)}`">
        <ul>
          <li
            v-for="(listItem, i) in listColumn.featuredListItem"
            :key="i"
            :style="globalTheme.color">
            {{listItem}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      listColumns: {
        type: Array as ()=> {featuredListItem: string}[],
        required: true
      },
    },
    methods: {
      generateColumnWidth(listLength: number): number {
        return 12/listLength
      }
    }
  })
</script>
