<template>
  <a
    v-if="isAbsoluteUrl"
    :href="to"
    aria-describedby="leave-page-label"
    target="_blank"
  >
    <slot></slot>
  </a>
  <router-link
    v-else
    v-slot="{isActive}"
    :class="{active: isActive}"
    :to="localizedRoute"
    :tag="tag">
    <slot></slot>
  </router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { isAbsoluteUrl } from '@/ts/utility.methods'
  export default defineComponent({
    props: {
      to: {
        required: true,
        type: String as () => string
      },
      tag: {
        type: String,
        required: false
      },
      leavePage: {
        type: Boolean,
        required: false
      },
    },
    computed: {
      clientID(): string {
        return this.$store.state.globals.clientId.toLowerCase()
      },
      selectedLanguage(): string {
        return this.$route.params.lang as string
      },
      computedTo(): string {
        const to = this.to as string || ''
        return to.startsWith('/') ? to.substr(1) : to
      },
      isAbsoluteUrl(): boolean {
        return isAbsoluteUrl(this.computedTo as string)
      },
      localizedRoute(): string {
        return `/${this.clientID}/${this.selectedLanguage}/${this.computedTo}`
      }
    },
  })
</script>
