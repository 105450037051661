<template>
  <router-view
    :key="baseRoute"/>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    computed: {
      baseRoute(): string {
        return `/${this.$route.params.clientID || ''}/${this.$route.params.lang || ''}`
      }
    }
  })
</script>
