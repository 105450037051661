<template>
  <app-section
    class="loading"
    grid="center justify-center">
    <font-awesome-icon
      aria-hidden="true"
      icon="spinner"
      pulse
      aria-label="Loading..."
      class="spinmeister"
      :style="globalTheme.color"
    />
  </app-section>
</template>
<style scoped>
.spinmeister {
    font-size:3rem;
}
</style>
