<template>
  <section class="member-demo-module choice-modal-links">
    <div class="choice-modals">
      <choice-modal 
        v-for="(choice,i) in section.choiceModal"
        :key="i"
        :choice="choice"/>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  import ChoiceModal from './ChoiceModals/ChoiceModal.vue'
  export default defineComponent({
    name: 'ChoiceModals',
    components: { ChoiceModal },
    props: {
      section: {
        required: true,
        type: Object as () => Boilerplate
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
