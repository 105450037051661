<template>
  <div class="desktop">
    <div container>
      <div grid="row wrap justify-start flex-end">
        <div column>
          <div class="log-search-wrap">
            <logo class="height-100" />
            <!-- <domain-selector class="domain-selector" /> -->
            <language-selector class="_mlxs language-selector" />
            <search
              class="_mlxs"
              :uid="'search_desktop'"/>
          </div>
          <nav
            id="dropdown-nav"
            aria-label="main desktop">
            <div class="dropdownBackground"></div>
            <ul
              id="primary-nav"
              ref="primaryNav"
              class="primary-nav">
              <template v-for="(item, i) in navItems">
                <li
                  v-if="item.secondTier && item.secondTier.length"
                  :key="`${i}_group`"
                  :style="globalTheme.color"
                  class="has-children tier-1 themed-tab"
                >
                  <button
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="nav-label simple"
                  >
                    {{ item.label }}
                    <caret-down />
                  </button>
                  <div
                    class="secondary-nav"
                    :style="globalTheme.borderColor">
                    <div container>
                      <ul class="column-wrap">
                        <template v-for="(item, j) in item.secondTier">
                          <li
                            v-if="item.externalUrl"
                            :key="`${j}_external`"
                            class="tier-2"
                          >
                            <a
                              :href="item.externalUrl"
                              target="_blank"
                              class="nav-column-header"
                              :style="[
                                globalTheme.color,
                                globalTheme.borderColor
                              ]"
                            >{{ item.label }}
                              <caret-right />
                            </a>
                          </li>
                          <li
                            v-else
                            :key="`${j}_internal`"
                            class="tier-2">
                            <localized-router-link
                              :to="item.url"
                              class="nav-column-header"
                              :style="[
                                globalTheme.color,
                                globalTheme.borderColor
                              ]"
                              :protected="item.protected"
                            >
                              {{ item.label }}
                              <caret-right />
                            </localized-router-link>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  v-else-if="item.externalUrl"
                  :key="`${i}_external`"
                  class="tier-1 themed-tab"
                  :style="globalTheme.color"
                >
                  <a
                    class="top-level-link"
                    :href="item.externalUrl"
                    target="_blank"
                  >
                    <p class="nav-label">
                      {{ item.label }}
                    </p>
                  </a>
                </li>
                <li
                  v-else
                  :key="`${i}_internal`"
                  class="tier-1 themed-tab"
                  :style="globalTheme.color"
                >
                  <localized-router-link
                    class="top-level-link"
                    :to="item.url"
                    :protected="item.protected"
                  >
                    <p class="nav-label">
                      {{ item.label }}
                    </p>
                  </localized-router-link>
                </li>
              </template>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import dropdownNav from '@molecules/Navigation/dropdown-navigation'
  import LanguageSelector from '@molecules/LanguageSelector.vue'
  import DomainSelector from '@molecules/DomainSelector.vue'
  import { defineComponent } from 'vue'

  import Logo from '@atoms/Logo.vue'
  import CaretRight from '@atoms/svg/CaretRight.vue'
  import CaretDown from '@atoms/svg/CaretDown.vue'
  import Search from './Search.vue'
  import { MainNavigationGroup } from '@/types'

  export default defineComponent({
    name: 'desktopNav' as string,
    components: {
      Logo,
      CaretRight,
      CaretDown,
      LanguageSelector,
      DomainSelector,
      Search
    },
    props: {
      navItems: {
        type: Array,
        required: false,
        default: () => []
      },
      colorOverride: {
        type: String,
        required: false
      }
    },
    watch: {
      $route() {
        this.$nextTick(() => {
          const allButtons = this.$el.querySelectorAll(
            '.has-children.tier-1 button'
          )
          allButtons.forEach(button => {
            button.classList.remove('list-holds-active')
          })

          const activeLink = this.$el.querySelector('.router-link-exact-active')
          if (!activeLink) return
          if (activeLink.classList.contains('nav-column-header')) {
            const listParent = activeLink.closest('.has-children.tier-1')
            if (listParent) {
              const listActivator = listParent.querySelector('button')
              listActivator.classList.add('list-holds-active')
            }
          }
        })
      }
    },
    mounted() {
      if (!this.navItems.length) return
      if (
        this.navItems.find((item: any): MainNavigationGroup | undefined => {
          return item.secondTier
        })
      ) {
        dropdownNav(this.$refs.primaryNav as HTMLElement).init()
      }
    }
  })
</script>
