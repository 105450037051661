<template>
  <img
    :src="src"
    alt="" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import SVGInjector from 'svg-injector'
  function removeGuts(svg): void {
    const childrenEls = svg.querySelectorAll('*') as SVGSymbolElement[]
    svg.removeAttribute('id')
    Array.from(childrenEls).map(el => {
      el.style.fill = 'currentColor'
      el.removeAttribute('id')
    })
  }
  export default defineComponent({
    name: 'inline-svg',
    props: {
      src: {
        type: String,
        required: true
      },
      forceColorOverride: {
        type: Boolean,
        default: true
      }
    },
    mounted(): void {
      SVGInjector(this.$el, {
        each: (svg) => {
          svg.setAttribute('role', 'presentation')
          svg.setAttribute('alt', '')
          svg.setAttribute('aria-hidden', 'true')
          if (!this.forceColorOverride || (this as any).isEyeMed) return
          removeGuts(svg)
        }
      })
    }
  })
</script>
