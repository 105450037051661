<template>
  <div class="logo-container">
    <localized-router-link
      to="/"
      :class="{'two-logos': !!secondaryLogo}">
      <template v-if="insuranceLogo">
        <div
          :style="`background-image: url(${insuranceLogo})`"
          class="insurance-logo-wrap">
          <img
            :src="insuranceLogo"
            :alt="`go to ${clientId} home page`"
            class="insurance-logo"
          >
        </div>
        <div
          v-if="secondaryLogo"
          :style="`background-image: url(${secondaryLogo})`"
          class="insurance-logo-wrap">
          <img
            :src="secondaryLogo"
            :alt="`${clientId} icon`"
            class="insurance-logo"
          >
        </div>
      </template>
      <template v-else>
        <img
          :src="logoImg"
          alt="EyeMed home page"
          class="logo"
        >
      </template>
    </localized-router-link>
  </div>
</template>

<script lang="ts">
import logoImg from '@/assets/images/svg/eyemed-logo.svg'
import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'logo' as string,
    data() {
      return {
        clientId: this.$route.params.clientID,
        logoImg:logoImg
      }
    },
    computed: {
      insuranceLogo(): string | undefined {
        const microsite = this.$store.state.globals
        return microsite && microsite.logo ? microsite.logo.url : undefined
      },
      secondaryLogo(): string | undefined {
        const microsite = this.$store.state.globals
        return microsite && microsite.secondaryLogo ? microsite.secondaryLogo.url : undefined
      } 
    },
  })
</script>
