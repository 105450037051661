<template>
  <section 
    v-if="!preventFetchByCat"
    class="member-demo-module featured-cards featured-post-links">
    <div container>
      <div
        v-if="section.title"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.colorText"
          class="_mbxs intro-title">
          {{ section.title }}
          <app-inline-svg
            v-if="section.titleSvgIcon"
            class="section-title-svg _mlxs"
            :style="globalTheme.fillText"
            :src="section.titleSvgIcon.url" />
        </h2>
      </div>
      <div grid="row wrap stretch">
        <div
          v-for="(post, i) in archiveCards"
          :key="i"
          column="xs-12 s-12 m-4 l-3"
          class="_mbs _mts"
        >
          <archive-card :post="post"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackPost,
  } from '@/types'

  import {
    postsState,
    FeaturedPostsSection,
    SiftedPosts
  } from '@/mixins/FeaturedPostsModuleState'

  import {
    ArchiveCardProps,
    archiveCardFactory
  } from '@/components/sections/FeaturedPosts/ArchiveCard.vue'

  import ArchiveCard from '@/components/sections/FeaturedPosts/ArchiveCard.vue'

  interface ArchiveCardsSections extends FeaturedPostsSection {
    manualEntries: ArchiveCardProps[];
  }

  export default defineComponent({
    name: 'ArchiveCards' as string,
    components: { ArchiveCard },
    mixins: [postsState],
    props: {
      section: {
        type: Object as () => ArchiveCardsSections,
        required: true
      }
    },
    computed: {
      archiveCards(): ArchiveCardProps[] {
        const postsToDisplay = (this as any).postsToDisplay as SiftedPosts
        if (postsToDisplay.referencedPostList) {
          return (postsToDisplay.posts as ContentStackPost[]).map(refPost =>
            archiveCardFactory(refPost, this.$store.state.globals.globalLabels.readMore)
          )
        } else {
          return postsToDisplay.posts as ArchiveCardProps[]
        }
      }
    }
  })
</script>
