import { createStore } from 'vuex'

export default createStore({
  state: {
    primaryColor: null,
    secondaryColor: null,
    currentRoute: null,
    page: null,
    globals: null,
    allCategories: null,
  },
  getters: {
    currentPostCategories: (state) => {
      if (state.page) {
        const referencedCats =  (state.page as any).category || []
        return referencedCats.filter(cat => !!cat.publishDetails)
      } else {
        return undefined
      }
    },
  },
  mutations: {
    setPrimaryColor(state, color): void {
      state.primaryColor = color
    },
    setSecondaryColor(state, color): void {
      state.secondaryColor = color
    },
    setCurrentRoute(state, route): void {
      state.currentRoute = route
    },
    setCurrentGlobals(state, globals): void {
      state.globals = globals
    },
    setCategoryList(state, fetchedCats): void {
      state.allCategories = fetchedCats
    },
    setCurrentPage(state, page): void {
      state.page = page
      if (document && document.title && state.globals) {
        document.title = `${(state.globals as any).applicationTitle}: ${page.pageTitle || page.title}`
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
