import { AdobePageObject } from '@/types'
import {
  updateAdobePageInfo,
  updateAdobeClientID
} from '@/ts/adobe-analytics/index'
// This mixin is intended to be used on page level components only. It will fire off updates to adobe analytics's window.digitalData object based on values within our app,  some of which must be fetched from contentstack (hence, why we can't do this as router middleware)
export const AdobeAnalytics = {
  methods: {
    updateAdobePageInfo,
    updateAdobeClientID,
    sendItToAdobe(pageTitle: string): void {
        const pageInfoPayload = {
            pageInfo: {
                pageName: pageTitle,
                sysEnv: 'browser'
              }
        } as AdobePageObject
        this.updateAdobePageInfo(pageInfoPayload)
        this.updateAdobeClientID((this as any).$route.params.clientID)
        window.digitalData.trackPageView(pageTitle)
    }
  }
}
