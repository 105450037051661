<template>
  <section class="small-callouts-with-media fg-will-change no-image member-demo-module">
    <div container>
      <small-callouts-title :section="section" />
      <small-callouts :section="section" />
      <div
        v-if="section.buttonLink && section.buttonLink.href"
        class="cta-wrap center-cta _mbl">
        <app-button-link
          :button="section.buttonLink"
          :style="globalTheme.button"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { SmallCallouts as SmallCalloutsInterface } from '@/types'
  import SmallCallouts from '@/components/sections/SmallCallouts/SmallCallouts.vue'
  import SmallCalloutsTitle from '@/components/sections/SmallCallouts/SmallCalloutsTitle.vue'
  export default defineComponent({
    name: 'small-callouts-section',
    components: {
      SmallCallouts,
      SmallCalloutsTitle,
    },
    props: {
      section: {
        required: true,
        type: Object as () => SmallCalloutsInterface
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
