import {
  FooterNavigation,
  MainNavigationItem,
  MainNavigationItemExternal
} from '@/types'
import {
  createInternalLink,
  createExternalLink
} from './main-navigation.factory'

export function footerNavigationFactory(
  microsite,
  clientID?: string
): FooterNavigation {
  const socialLinks = microsite.socialLinks
  const footerNavColumns = microsite.footerNavItems

  return {
    socialLinks: socialLinks
      ? socialLinks.map(socialLink => {
          const icon = socialLink.icon
          const link = socialLink.link
          return {
            iconPath: icon ? icon.url : '',
            url: link ? link.href : '',
            alt: link ? link.title : '',
          }
        })
      : undefined,
    navItems: footerNavColumns
      ? footerNavColumns.map(navList => {
        const navColumn = navList.linkColumn 
          return {
            columnHeaderInternalLink: navColumn.columnHeaderInternalLink
              ? createInternalLink(
                  navColumn.columnHeaderInternalLink[0],
                  navColumn.columnHeaderLinkTitle,
                  clientID
                )
              : null,
            columnHeaderExternalLink: navColumn.columnHeaderExternalLinkUrl
              ? {
                  label: navColumn.columnHeaderLinkTitle as string,
                  externalUrl: navColumn.columnHeaderExternalLinkUrl as string
                }
              : null,

            subLinks: navColumn.links.map(navItem => {
              if (navItem.internalLink) {
                const link = navItem.internalLink
                return createInternalLink(
                  link.pageLink[0],
                  link.linkTitle,
                  clientID
                ) as MainNavigationItem
              } else {
                const externalLink = navItem.externalLink
                const link = externalLink.link
                const totalLink = { ...externalLink, ...link }
                return createExternalLink(
                  totalLink
                ) as MainNavigationItemExternal
              }
            })
          }
        })
      : undefined,
    contactCta: microsite.contactCta
  }
}
