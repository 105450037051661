<template>
  <section
    class="member-demo-module featured-copy single combo-content-feature"
    :class="{ 'reverse-order': section.mirrorLayout }"
  >
    <div container>
      <div grid="row wrap stretch">
        <div
          v-if="section.comboBlock.image"
          column="xs-12 s-12 m-6 l-4"
          class="combo-block-wrap"
        >
          <div
            class="combo-block" 
            :style="globalTheme.backgroundColor">
            <app-image
              :image="section.comboBlock.image"
              :alt="section.comboBlock.imageAlt"
              class="full-width"
            />
            <div
              class="content _pm">
              <h2
                v-if="comboBlock.heading"
                class="section-header h2"
              >
                {{ comboBlock.heading }}
              </h2>
              <rich-text
                v-if="comboBlock.content"
                class="content"
                :html="comboBlock.content"
              ></rich-text>
            </div>
          </div>
        </div>
        <div
          class="featured-copy-wrap"
          :style="setBackgroundColor(section)"
          column="xs-12 s-12 m-6 l-8">
          <div class="feature-wrap">
            <div class="content-wrap">
              <app-inline-svg
                v-if="smallFeature.icon && smallFeature.icon.url"
                class="title-svg"
                :src="smallFeature.icon.url"
                :style="[globalTheme.color, globalTheme.fill]"
              />
              <h2
                v-if="smallFeature.sectionTitle"
                :style="globalTheme.color"
                class="section-header h2"
              >
                {{ smallFeature.sectionTitle }}
              </h2>
              <rich-text
                v-if="smallFeature.content"
                class="content"
                :html="smallFeature.content"
              ></rich-text>
              <resource
                v-if="resourceFactory(smallFeature)"
                :resource="resourceFactory(smallFeature)"
                :background-as-offwhite="section.colorOptions.backgroundAsOffwhite"
              />
            </div>
            <app-button-link
              :button="smallFeature.buttonLink"
              :style="globalTheme.button"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ButtonLink,
    ContentStackFile,
    Resource as ResourceInterface,
    Boilerplate
  } from '@/types'
  import Resource from '@/components/sections/Shared/Resource.vue'

  interface IndividualFeature {
    icon?: ContentStackFile;
    sectionTitle?: string;
    content?: string;
    resource?: ResourceInterface;
    resourceLink?: string;
    resourceTitle?: string;
    svgIcon?: ContentStackFile;
    buttonLink?: ButtonLink;
  }

  interface ComboBlock {
    image?: ContentStackFile;
    imageAlt?: string;
    header?: string;
    content?: string;
  }

  export interface ComboContentFeatureProps extends Boilerplate {
    featuredContent: IndividualFeature;
    comboBlock: ComboBlock;
    mirrorLayout: boolean;
  }
  import { resourceFactory } from '@/ts/factories/resource.factory'

  export default defineComponent({
    name: 'ComboContentFeature',
    components: {
      Resource
    },
    props: {
      section: {
        required: true,
        type: Object as () => ComboContentFeatureProps
      }
    },
    data() {
      return {
        smallFeature: this.section.featuredContent,
        comboBlock: this.section.comboBlock
      }
    },
    methods: {
      resourceFactory,
      setBackgroundColor(section): { backgroundColor: string } {
        return {
          backgroundColor:
            section.colorOptions && section.colorOptions.backgroundAsOffwhite
              ? '#fff'
              : '#F8F8F8'
        }
      },
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
<style scoped>
.btn {
  align-self: flex-start;
}
</style>
