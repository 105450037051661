<template>
  <div class="member-demo-module post-body">
    <hero-with-content :section="heroContent" />
    <simple-rich-text
      v-if="hasPostBodyContent"
      :section="simpleRichTextContent"/>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackFile, Hero } from '@/types'
  import HeroWithContent from '@/components/sections/HeroWithContent.vue'
  import SimpleRichText from './SimpleRichText.vue'
  import { SimpleRichTextProps } from './SimpleRichText.vue'

  export interface PostBodyProps extends SimpleRichTextProps {
    featuredImage?: ContentStackFile
    featuredThumbnail?: ContentStackFile
    featuredImageAlt?: string
    featuredThumbnailAlt?: string
  }

  export default defineComponent({
    name: 'post-body',
    components: { HeroWithContent, SimpleRichText },
    props: {
      section: {
        required: true,
        type: Object as () => PostBodyProps,
      },
    },
    computed: {
      heroContent(): Hero {
        return {
          heroTitle: this.section.title,
          imageDesktop: this.section.featuredImage,
          imageMobile: this.section.featuredThumbnail,
          heroAlt: this.section.featuredImageAlt,
          heroAltMobile: this.section.featuredThumbnailAlt,
          colorOptions: {
            ...this.section.colorOptions,
            backgroundAsOffwhite: this.section.colorOptions
              ? !this.section.colorOptions.backgroundAsOffwhite
              : true,
          },
        }
      },
      simpleRichTextContent(): SimpleRichTextProps {
        return {
          content: this.section.content,
          colorOptions: {
            ...this.section.colorOptions,
          },
          sidebar: this.section.sidebar,
          accordionDrawers: this.section.accordionDrawers,
          buttonLink: this.section.buttonLink,
        }
      },
      hasPostBodyContent(): boolean {
        return (
          !!this.section.content ||
          (!!this.section.buttonLink &&
            !!this.section.buttonLink.title &&
            !!this.section.buttonLink.href) ||
          (!!this.section.accordionDrawers &&
            this.section.accordionDrawers.length !== 0)
        )
      },
    },
    methods: {
      printPage(): void {
        window.print()
      },
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions,
      }
    },
  })
</script>
<style lang="scss" scoped>
  .hero-image {
    width: 100%;
    height: auto;
  }
</style>
