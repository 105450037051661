<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.6719 20.7656L18.9844 16.0781C18.75 15.8906 18.4688 15.75 18.1875 15.75H17.4375C18.7031 14.1094 19.5 12.0469 19.5 9.75C19.5 4.40625 15.0938 0 9.75 0C4.35938 0 0 4.40625 0 9.75C0 15.1406 4.35938 19.5 9.75 19.5C12 19.5 14.0625 18.75 15.75 17.4375V18.2344C15.75 18.5156 15.8438 18.7969 16.0781 19.0312L20.7188 23.6719C21.1875 24.1406 21.8906 24.1406 22.3125 23.6719L23.625 22.3594C24.0938 21.9375 24.0938 21.2344 23.6719 20.7656ZM9.75 15.75C6.42188 15.75 3.75 13.0781 3.75 9.75C3.75 6.46875 6.42188 3.75 9.75 3.75C13.0312 3.75 15.75 6.46875 15.75 9.75C15.75 13.0781 13.0312 15.75 9.75 15.75Z"
      fill="currentColor"/>
  </svg>
</template>
