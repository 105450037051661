<template>
  <article-card
    :post="articleCardFactory(post)" 
    show-post-type
    class="wide"/>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackPost } from '@/types'
  import ArticleCard from './ArticleCard.vue'
  import { articleCardFactory } from './ArticleCard.vue'

  export default defineComponent({
    name: 'PostCardSingle',
    components: { ArticleCard },
    props: {
      post: {
        type: Object as () => ContentStackPost,
        required: true
      }
    },
    methods: {
      articleCardFactory
    }
  })
</script>
