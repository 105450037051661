<template>
  <div class="feature-wrap">
    <div class="content-wrap">
      <app-inline-svg
        v-if="smallFeature.icon && smallFeature.icon.url"
        class="title-svg"
        :src="smallFeature.icon.url"
        :style="[globalTheme.color, globalTheme.fill]"
      />
      <h2
        v-if="smallFeature.sectionTitle"
        :style="globalTheme.color"
        class="section-header h2"
      >
        {{ smallFeature.sectionTitle }}
      </h2>
      <rich-text
        v-if="smallFeature.content"
        class="content"
        :html="smallFeature.content"
      ></rich-text>
      <resource
        v-if="resource"
        :resource="resource"
        :background-as-offwhite="backgroundAsOffwhite"
      />
    </div>
    <app-button-link
      :button="smallFeature.buttonLink"
      :style="globalTheme.button"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { IndividualFeature } from '@/types'
  import { resourceFactory } from '@/ts/factories/resource.factory'
  import Resource from '@/components/sections/Shared/Resource.vue'
  export default defineComponent({
    name: 'SingleChoiceModal',
    components: {
      Resource
    },
    props: {
      smallFeature: {
        required: true,
        type: Object as () => IndividualFeature
      },
      backgroundAsOffwhite: {
        required: false,
        type: Boolean
      }
    },
    computed: {
      colorOverride(): string | undefined | unknown {
        const colorOverrideObject =
          this.smallFeature.themeColorOverride &&
          this.smallFeature.themeColorOverride.length
            ? this.smallFeature.themeColorOverride[0]
            : {}
        return colorOverrideObject.colorHex as string | undefined
      },
      resource() {
        return resourceFactory(this.smallFeature)
      }
    }
  })
</script>
<style scoped>
.btn {
  align-self: flex-start;
}
</style>
