<template>
  <div>
    <label
      for="language-selector"
      class="visuallyhidden"
    >Please select a language</label
    >
    <select
      v-model.trim="selectedLanguage"
      class="language-selector"
      name="language-selector"
    >
      <option
        v-for="(option, i) in languages"
        :key="i"
        :value="option.value">{{
          option.label
        }}</option>
    </select>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'LanguageSelector',
    data() {
      return {
        languages: [
          { label: 'English', value: 'en' },
          { label: 'Espanol', value: 'es' }
        ],
        selectedLanguage: this.$route.params.lang
      }
    },
    watch: {
      selectedLanguage: {
        handler(selectedLanguage) {
          this.changeLanguage(selectedLanguage)
        }
      }
    },
    methods: {
      changeLanguage(selectedLanguage: string): void {
        this.$router
          .push({
            name: this.$route.name as string || undefined,
            params: {
              clientID: this.$route.params.clientID,
              lang: selectedLanguage
            }
          })
      }
    }
  })
</script>
