<template>
  <footer>
    <div class="main-footer">
      <div container>
        <div grid="row wrap">
          <div column="xs-12 s-12 l-2"><logo /></div>
          <div
            v-if="linkColumnLength"
            column="xs-12 s-12 m-12 l-8">
            <nav aria-label="footer nav">
              <ul
                v-for="(linkColumn, i) in footerContent.navItems"
                :key="i">
                <li
                  v-if="linkCoumnHasTitle(linkColumn)"
                  class="link-column-title">
                  <h5>
                    <localized-router-link
                      v-if="linkColumn.columnHeaderInternalLink && !linkColumn.columnHeaderInternalLink.hideLink"
                      :to="linkColumn.columnHeaderInternalLink.url"
                      :style="globalTheme.color"
                    >
                      {{ linkColumn.columnHeaderInternalLink.label }}
                    </localized-router-link>
                    <a
                      v-else-if="linkColumn.columnHeaderExternalLink && linkColumn.columnHeaderExternalLink.externalUrl"
                      :href="linkColumn.columnHeaderExternalLink.externalUrl"
                      target="_blank"
                      :style="globalTheme.color"
                    >{{ linkColumn.columnHeaderExternalLink.label }}</a
                    >
                    <template v-else-if="linkColumn.columnHeaderExternalLink && linkColumn.columnHeaderExternalLink.label">
                      {{ linkColumn.columnHeaderExternalLink.label }}
                    </template>
                  </h5>
                </li>   
                <template 
                  v-for="(item, i) in linkColumn.subLinks"
                  :key="i">
                  <li v-if="listLinkExists">
                    <localized-router-link
                      v-if="item.url && !item.hideLink"
                      :to="item.url"
                      :style="globalTheme.colorBlack"
                    >
                      {{ item.label }}
                    </localized-router-link>
                    <a
                      v-else-if="item.externalUrl"
                      :href="item.externalUrl"
                      target="_blank"
                      :style="globalTheme.colorBlack"
                    >{{ item.label }}</a
                    >
                  </li>
                </template> 
              </ul>
            </nav>
          </div>
          <div
            v-if="hasContactCTA"
            column="xs-6 s-6"
            class="mobile _mts">
            <localized-router-link
              class="btn _m0"
              :to="footerContent.contactCta.href"
              :style="globalTheme.button"
            >
              {{ footerContent.contactCta.title }}
            </localized-router-link>
          </div>
          <div
            :column="hasContactCTA ? 'xs-6 s-6' : 'xs-12 s12'"
            class="mobile">
            <nav
              v-if="footerContent.socialLinks.length"
              aria-label="footer social nav"
              class="social "
              :class="{'has-cta': hasContactCTA}">
              <a
                v-for="(link, i) in footerContent.socialLinks"
                :key="i"
                :href="link.url"
                :aria-label="link.alt"
                target="_blank"
              >
                <img
                  :alt="link.alt"
                  :src="link.iconPath" />
              </a>
            </nav>
          </div>
          <div
            column="xs-12 s-12 m-12 l-2"
            class="desktop _pl0">
            <localized-router-link
              v-if="footerContent.contactCta && footerContent.contactCta.href"
              class="btn"
              :to="footerContent.contactCta.href"
              :style="globalTheme.button"
            >
              {{ footerContent.contactCta.title }}
            </localized-router-link>
            <nav
              v-if="footerContent.socialLinks.length"
              aria-label="footer social nav"
              class="social ">
              <a
                v-for="(link, i) in footerContent.socialLinks"
                :key="i"
                :href="link.url"
                :aria-label="link.alt"
                target="_blank"
              >
                <img
                  :alt="link.alt"
                  :src="link.iconPath" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { FooterNavigation } from '@/types'
  import Logo from '@atoms/Logo.vue'
  export default defineComponent({
    components:{ Logo },
    props: {
      footerContent: {
        required: true,
        type: Object as () => FooterNavigation
      }
    },
    data() {
      return {
        hasContactCTA: this.footerContent.contactCta && this.footerContent.contactCta.href,
        linkColumnLength: this.footerContent.navItems.length
      }
    },
    methods: {
      linkCoumnHasTitle(linkColumn): boolean {
        return (
          linkColumn.columnHeaderInternalLink && !linkColumn.columnHeaderInternalLink.hideLink ||
          linkColumn.columnHeaderExternalLink && linkColumn.columnHeaderExternalLink.externalUrl ||
          linkColumn.columnHeaderExternalLink && linkColumn.columnHeaderExternalLink.label
        )
      },
      listLinkExists(item): boolean {
        return (
          item.url && !item.hideLink ||
          item.externalUrl
        )
      }
    }
  })
</script>
