// Factories
import { smallCalloutsFactory } from '@/ts/factories/smallcallouts.factory'

export function defaultFactory(
  section: Record<string, any>
): Record<string, any> {
  const colorOptions = section.colorOptions || section.colors
  if (
    colorOptions &&
    colorOptions.themeColorOverride &&
    colorOptions.themeColorOverride.length
  ) {
    colorOptions.colorOverride = colorOptions.themeColorOverride[0].colorHex
  }
  section.colorOptions = colorOptions
  return section
}

function smallCalloutsWithColorsSet(section: Record<string, any>) {
  return smallCalloutsFactory(defaultFactory(section))
}

function seachGlossaryFactory(section: Record<string, any>) {
  if (section.terms && section.terms.length) {
    section.terms.map(term => {
      if (term.associatedPages && term.associatedPages.length) {
        term.associatedPages = term.associatedPages.filter(page => !!page.url)
      }
      return term
    })
  }
  return defaultFactory(section)
}

export default function(name: string): any {
  switch (name) {
    case 'smallCalloutsWithImage':
      return smallCalloutsWithColorsSet
    case 'searchGlossary':
      return seachGlossaryFactory
    default:
      return defaultFactory
  }
}
