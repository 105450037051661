<template>
  <section class="member-demo-module glossary _p0">
    <div
      v-if="section.title && !searchGlossary"
      :style="globalTheme.backgroundColor"
      class="glossary-title"
    >
      <h2 container>
        {{ section.title }}
      </h2>
    </div>
    <div class="_ptl _pbxl">
      <div
        class="control mobile alphabet"
        :style="globalTheme.color">
        <div class="glossary-buttons">
          <button
            v-for="i in 26"
            :key="`letter_${(i + 9).toString(36)}`"
            :class="[
              {
                selected: isSelected((i + 9).toString(36)) && !searchTerm,
              },
              { 'has-terms': hasTerms((i + 9).toString(36)) },
            ]"
            :disabled="!hasTerms((i + 9).toString(36))"
            @click="selectLetter(i)"
          >
            {{ (i + 9).toString(36) }}
          </button>
        </div>
      </div>
      <div container>
        <div grid="row wrap justify-center">
          <div column="xs-12 s-12">
            <div
              class="control desktop alphabet"
              :style="globalTheme.color">
              <div class="glossary-buttons">
                <button
                  v-for="i in 26"
                  :key="`letter_${(i + 9).toString(36)}`"
                  :class="[
                    {
                      selected: isSelected((i + 9).toString(36)) && !searchTerm,
                    },
                    { 'has-terms': hasTerms((i + 9).toString(36)) },
                  ]"
                  :disabled="!hasTerms((i + 9).toString(36))"
                  @click="selectLetter(i)"
                >
                  {{ (i + 9).toString(36) }}
                </button>
              </div>
            </div>
          </div>

          <div column="xs-12 s-12 m-10 l-8 xl-9">
            <div class="search-wrapper _mtm">
              <div class="form-group">
                <label :for="glossaryUID">{{ section.searchLabel }}</label>
                <div class="input-wrap">
                  <input
                    :id="glossaryUID"
                    ref="search_input"
                    v-model="searchTerm"
                    type="text"
                  />
                  <app-inline-svg
                    :style="globalTheme.fill"
                    :src="searchIcon" />
                </div>
              </div>
            </div>
          </div>

          <div
            :column="searchGlossary ? 'xs-12 s-12' : 'xs-12 s-12 m-10 l-8 xl-9'"
          >
            <div class="flex-wrapper _mtl">
              <div
                v-if="selectedTerm"
                :key="`${selectedTerm}_${tick}`"
                class="selected-term"
              >
                <h3
                  :style="globalTheme.color"
                  class="_mbxs">
                  {{ selectedTerm.name }}
                </h3>

                <pagination
                  v-if="isSearchList && totalPages > 1"
                  :key="`above_${currentPage}`"
                  v-model="currentPage"
                  aria-label="search results pagination"
                  class="_mbs"
                  :page-count="totalPages"
                  :page-range="pageRange"
                />

                <ul
                  v-if="paginatedSearchResults.length"
                  :key="currentPage"
                  class="search-results"
                >
                  <search-page-link
                    v-for="(link, l) in paginatedSearchResults"
                    :key="l"
                    :search-page-link="link"
                  />
                </ul>

                <pagination
                  v-if="isSearchList && totalPages > 1"
                  :key="`below_${currentPage}`"
                  v-model="currentPage"
                  aria-label="search results pagination"
                  class="_mts"
                  :page-count="totalPages"
                  :page-range="pageRange"
                />

                <rich-text
                  v-else
                  :html="selectedTerm.definition"
                  :theme-color-override="
                    section.colorOptions
                      ? section.colorOptions.themeColorOverride
                      : null
                  "
                />
              </div>

              <div
                v-else
                class="selected-term _text-center">
                <rich-text
                  :html="section.noResultsMessage"
                  :theme-color-override="
                    section.colorOptions
                      ? section.colorOptions.themeColorOverride
                      : null
                  "
                />

                <button
                  class="btn _mtm"
                  :style="globalTheme.button"
                  :disabled="!searchTerm"
                  @click="clearSearch"
                >
                  {{ labels.clearSearch }}
                </button>
              </div>

              <div
                v-if="selectedTermList.length > 1"
                class="index-controls">
                <button
                  class="control"
                  :style="globalTheme.color"
                  @click="setTermIndex(-1)"
                >
                  <font-awesome-icon
                    aria-hidden="true"
                    icon="chevron-up" />
                </button>
                <button
                  class="control"
                  :style="globalTheme.color"
                  @click="setTermIndex(1)"
                >
                  <font-awesome-icon
                    aria-hidden="true"
                    icon="chevron-down" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  import { v4 as uuidv4 } from 'uuid'
  import searchIcon from '@/assets/images/svg/icon-ui-search.svg'

  import { SearchPageLinkProps } from './Glossary/SearchPageLink.vue'

  export interface TermProps {
    name: string
    associatedPages?: SearchPageLinkProps[]
    definition?: string
  }

  export interface GlossaryProps extends Boilerplate {
    terms?: TermProps[]
    searchLabel?: string
    noResultsMessage?: string
  }

  import SearchPageLink from './Glossary/SearchPageLink.vue'
  import Pagination from '@/components/molecules/Pagination.vue'

  export default defineComponent({
    name: 'glossary',
    components: { Pagination, SearchPageLink },
    props: {
      section: {
        type: Object as () => GlossaryProps,
        required: true,
      },
      searchGlossary: {
        type: Boolean,
      },
      searchQuery: {
        type: String,
      },
      pageRange: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        searchIcon,
        selectedLetter: this.getFirstTermLetter(),
        selectedTermIndex: 0,
        storedTermIndex: 0,
        tick: 0,
        glossaryUID: `glossary_${uuidv4()}`,
        searchTerm: this.searchQuery || '',
        labels: this.$store.state.globals.globalLabels,
        currentPage: 1,
      }
    },
    computed: {
      selectedTermList(): TermProps[] {
        const allTerms = this.section.terms || []
        let selectedTerms
        if (this.searchTerm) {
          selectedTerms = allTerms.filter((termObj) =>
            termObj.name.toLowerCase().includes(this.searchTerm.toLowerCase())
          )
        } else {
          selectedTerms = allTerms
        }
        return selectedTerms.sort((a, b) => a.name.localeCompare(b.name))
      },
      selectedTerm(): TermProps | undefined {
        return this.selectedTermList[this.selectedTermIndex]
      },
      isSearchList(): boolean {
        return !!this.selectedTerm &&
          !!this.selectedTerm.associatedPages &&
          this.selectedTerm.associatedPages.length > 0
      },
      totalPages(): number | undefined {
        let count
        if (
          !!this.selectedTerm &&
          !!this.selectedTerm.associatedPages &&
          this.selectedTerm.associatedPages.length > 0
        ) {
          count = this.selectedTerm.associatedPages.length
        } else {
          count = 0
        }
        return Math.ceil(count / this.pageRange)
      },
      paginatedSearchResults(): SearchPageLinkProps[] {
        if (!!this.selectedTerm &&
          !!this.selectedTerm.associatedPages &&
          this.selectedTerm.associatedPages.length > 0) {
          const sliceStart = (this.currentPage - 1) * this.pageRange
          const sliceEnd = this.currentPage * this.pageRange
          return this.selectedTerm.associatedPages.slice(sliceStart, sliceEnd)
        } else {
          return []
        }
      }
    },
    watch: {
      searchTerm(newVal, oldVal) {
        this.currentPage = 1
        if (newVal) {
          if (!oldVal) {
            this.storedTermIndex = this.selectedTermIndex
          }
          this.selectedTermIndex = 0
          this.tick++
        } else {
          this.selectedTermIndex = this.storedTermIndex
          this.tick++
        }
      },
      selectedTerm: {
        handler(newValue) {
          if (newValue && newValue.name) {
            if (newValue.name[0].toLowerCase() !== this.selectedLetter) {
              this.selectedLetter = newValue.name[0].toLowerCase()
            }
          }
        },
        deep: true,
      },
    },
    methods: {
      clearSearch(): void {
        this.searchTerm = ''
        this.currentPage = 1
      },
      disableLetterSelection(index: number): boolean {
        return !this.hasTerms((index + 9).toString(36)) || !!this.searchTerm
      },
      hasTerms(letter: string): boolean {
        const allTerms = this.section.terms || []
        return !!allTerms.find(
          (termObj) => termObj.name[0].toLowerCase() === letter
        )
      },
      isSelected(letter: string): boolean {
        return letter === this.selectedLetter
      },
      selectLetter(index: number): void {
        this.searchTerm = ''
        this.currentPage = 1
        this.$nextTick(() => {
          const letter = (index + 9).toString(36)
          this.tick += 1
          this.selectedLetter = letter
          const newTermObj = this.selectedTermList.find(
            (term) => term.name[0].toLowerCase() === letter
          )
          if (newTermObj) {
            this.selectedTermIndex = this.selectedTermList.indexOf(newTermObj)
          }
        })
      },
      setTermIndex(num: number): void {
        this.currentPage = 1
        this.tick += 1
        const newIndex = this.selectedTermIndex + num
        const endIndex = this.selectedTermList.length - 1
        if (newIndex > endIndex) {
          this.selectedTermIndex = 0
        } else if (newIndex < 0) {
          this.selectedTermIndex = endIndex
        } else {
          this.selectedTermIndex = newIndex
        }
      },
      getFirstTermLetter(): string {
        if (this.searchQuery) {
          return this.searchQuery[0].toLowerCase()
        }
        const allTerms = this.section.terms || []
        const sortedTerms = allTerms.sort((a, b) => a.name.localeCompare(b.name))
        const firstSortedTerm = sortedTerms[0]
        return firstSortedTerm && firstSortedTerm.name
          ? firstSortedTerm.name[0].toLowerCase()
          : 'a'
      },
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions,
      }
    },
    mounted() {
      if (this.searchQuery) {
        (this.$refs.search_input as HTMLFormElement).focus()
      }
    },
  })
</script>
