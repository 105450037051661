<template>
  <section class="member-demo-module content-with-video">
    <div container>
      <div
        v-if="section.title"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.colorText"
          class="_mbs intro-title"
        >
          {{ section.title }}
          <app-inline-svg
            v-if="section.titleSvgIcon"
            class="section-title-svg _mlxs"
            :style="globalTheme.fillText"
            :src="section.titleSvgIcon.url"
          />
        </h2>
      </div>

      <div grid="row wrap stretch">

        <div
          v-if="featuredVideo"
          :key="`featured_${tick}`"
          :column="restVideos.length ? 'xs-12 s-12 m-8' : 'xs-12 s-12' ">
          <div class="feat-video-wrap">
            <button
              v-if="restVideos.length"
              class="prev-video"
              :style="globalTheme.colorText"
              aria-label="Previous Video"
              @click="prevVideo"
            >
              <chevron-right />
            </button>
            <button
              v-if="restVideos.length"
              class="next-video"
              :style="globalTheme.colorText"
              aria-label="Next Video"
              @click="nextVideo"
            >
              <chevron-right />
            </button>
            <VideoBlock
              :video="featuredVideo.video"
              :poster="featuredVideo.videoPoster"
              :description="featuredVideo.videoDescriptionTrack"
              :subtitles="featuredVideo.videoSubtitleTrack"
              :embed="featuredVideo.embedUrl"
            />
          </div>
        </div>
        <div
          v-if="sideBarVideos.length"
          column="xs-12 s-12 m-4"
          class="tablet-up sidebar">
          <VideoBlock
            v-for="(video, j) in sideBarVideos"
            :key="`${j}_${tick}`"
            :video="video.video"
            :poster="video.videoPoster"
            :description="video.videoDescriptionTrack"
            :subtitles="video.videoSubtitleTrack"
            :embed="video.embedUrl"
            class="sidebar-vid-preview"
          />
        </div>
        <template
          v-if="rowVideos.length">
          <VideoBlock
            v-for="(video, j) in rowVideos"
            :key="`${j}_${tick}`"
            column="xs-12 s-12 m-4"
            class="tablet-up rest-row-vid-preview"
            :video="video.video"
            :poster="video.videoPoster"
            :description="video.videoDescriptionTrack"
            :subtitles="video.videoSubtitleTrack"
            :embed="video.embedUrl"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate, ContentStackFile } from '@/types'
  import VideoBlock from '@/components/sections/ContentWithVideo/VideoBlock.vue'

  interface ContentWithVideo extends Boilerplate {
    videos: ContentStackFile[];
  }

  import ChevronRight from '@/components/_global/Svg/ChevronRight.vue'

  export default defineComponent({
    name: 'ContentWithVideo',
    components: {
      VideoBlock,
      ChevronRight
    },
    props: {
      section: {
        required: true,
        type: Object as () => ContentWithVideo
      }
    },
    data() {
      return {
        videos: this.section.videos || ([] as ContentStackFile[]),
        tick: 0,
        slideRight: true
      }
    },
    computed: {
      featuredVideo(): ContentStackFile | null {
        if (!this.videos || !this.videos.length) return null
        return this.videos[0]
      },
      restVideos(): ContentStackFile[] {
        if (!this.videos || !this.videos.length || !this.featuredVideo) return []
        return this.videos.filter(video => video !== this.featuredVideo)
      },
      sideBarVideos(): ContentStackFile[] {
        if (!this.restVideos.length || !this.featuredVideo) return []
        return this.restVideos.slice(0, 2)
      },
      rowVideos(): ContentStackFile[] {
        if (!this.restVideos.length || !this.featuredVideo) return []
        return this.restVideos.filter((video, i) => {
          return i > 1
        })
      }
    },
    methods: {
      nextVideo(): void {
        this.slideRight = true
        this.tick++
        if (this.videos.length) {
          const firstVideo = this.videos.shift() as ContentStackFile
          this.videos.push(firstVideo)
        }
      },
      prevVideo(): void {
        this.slideRight = false
        this.tick++
        if (this.videos.length) {
          const lastVideo = this.videos.pop() as ContentStackFile
          this.videos.unshift(lastVideo)
        }
      }
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
