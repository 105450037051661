import { BackgroundCoordinates, Dimension } from '@/types'

const isMobileSafari = function (): RegExpMatchArray | null {
  return window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)
}

export default function (el: HTMLElement): any {
  const primaryNav = el
  let triggers
  let antiTriggers

  if (primaryNav) {
    triggers = primaryNav.querySelectorAll('.has-children.tier-1')
    antiTriggers = primaryNav.querySelectorAll('.tier-1:not(.has-children)')
  }

  if (!triggers || triggers.length === 0) {
    return {
      init: () => null,
      handleLeave: () => null
    }
  }

  const delay = 350
  const delayAdditive = 50
  const shiftingBackground: HTMLElement | null = document.querySelector('.dropdownBackground')
  const nav: Element | null = document.querySelector('#dropdown-nav')
  // const links: NodeListOf<HTMLAnchorElement> = nav!.querySelectorAll('a')
  const restPosition = primaryNav.offsetHeight

  const windowHeight: number = window.innerHeight || document.documentElement.clientHeight
  const windowWidth: number = window.innerWidth || document.documentElement.clientWidth
  let clearEventType = 'mousemove'
  let linkOpacTimeout  = 0
  let morphTimeout  = 0

  if (isMobileSafari()) {
    clearEventType = 'touchstart'

    document.querySelector('body')!.classList.add('mobile-safari')

    for (const secondNav of document.querySelectorAll('.secondary-nav')) {
      secondNav.classList.add('mobile-safari')
    }
  }

  // function removeClickFocus(elements: HTMLElement[]): void {
  //   Array.from(elements as HTMLElement[]).map((el: HTMLElement) => {
  //     el.addEventListener('click' as string, (e: Event) => {
  //       const target: any = e.target
  //       target!.blur()
  //     })

  //     el.addEventListener('contextmenu' as string, (e: Event) => {
  //       const target: any = e.target
  //       target!.blur()
  //     })
  //   })
  // }

  function isDescendant(parent: ParentNode, child?: Node): boolean {
    if (!child) return true
    let node: (Node & ParentNode) | null = child.parentNode

    while (node !== null) {
      if (node === parent) {
        return true
      }
      node = node.parentNode
    }
    return false
  }

  function setHoverClass(): void {
    primaryNav.classList.add('hovered')
  }

  function removeHoverClass(): void {
    primaryNav.classList.remove('hovered')
  }

  function clearTimeouts(): void {
    if (linkOpacTimeout > 0) {
      window.clearTimeout(linkOpacTimeout)
    }

    if (morphTimeout > 0) {
      window.clearTimeout(morphTimeout)
    }

    morphTimeout = 0
    linkOpacTimeout = 0
  }

  function clearDropdown(): void {
    shiftingBackground!.classList.remove('open')
    shiftingBackground!.style.height = '0'
    shiftingBackground!.style.width = '200vw'
    shiftingBackground!.style.transform = `translate(-50vw, ${restPosition}px)`
    primaryNav.classList.remove('sibling-hovered')
  }

  function getDimension(item, dimension) {
    return item[dimension]
  }

  function sum(prev: number, next: number): number {
    return prev + next
  }

  function isInViewport(elem: HTMLElement): boolean {
    const bounding = elem.getBoundingClientRect()
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= windowHeight &&
      bounding.right <= windowWidth
    )
  }

  function forceCorrectHeight(parent: HTMLElement, elToCheck: HTMLElement, minHeight: number): void {
    parent.style.height = `${minHeight + 50}px`

    if (!isInViewport(elToCheck) && minHeight < windowHeight) {
      window.requestAnimationFrame(() => {
        return forceCorrectHeight(parent, elToCheck, minHeight + 50)
      })
    } else {
      return
    }
  }

  function setGridHeights(trigger: HTMLElement): void {
    const grid: HTMLElement | null = trigger.querySelector('.column-wrap')
    if (!grid) {
      return
    }

    const columns: NodeListOf<HTMLElement> = grid.querySelectorAll('.tier-2')

    const dimensions: Dimension[] = [ ...columns ].map(el => {
      return {
        height: el.offsetHeight,
        width: el.offsetWidth,
        area: el.offsetHeight * el.offsetWidth
      }
    })

    const largest: Dimension = dimensions.reduce((a: Dimension, b: Dimension) => {
      return a.area > b.area ? a : b
    })

    const columnstotalHeight: number = dimensions.map(item => {
      return getDimension(item, 'height')
    }).reduce(sum)

    const lastColumn: HTMLElement = [ ...columns ].slice(-1)[0]

    if (columnstotalHeight > (windowHeight - 130)) {
      //window.addEventListener('load', () => {
        forceCorrectHeight(grid, lastColumn, largest.height)
      //})
    } else {
      trigger.classList.add('thin')
      trigger!.querySelector('.secondary-nav')!.classList.add('thin')
    }
  }

  function clearFocus(target = nav): void {
    const activeElement = document.activeElement
    if (!target!.contains(activeElement) && nav!.contains(activeElement)) {
      (activeElement as HTMLElement).blur()
    }
  }

  function clearFocusOnExit(): void {
    document.body.classList.remove('nav-expanded')
    const activeElement = document.activeElement
    if (nav!.contains(activeElement)) {
      (activeElement as HTMLElement).blur()
    }
  }

  function morphBackground(target: Element) {
    clearFocus(target)

    const dropdown: HTMLElement | null = target.querySelector('.secondary-nav')
    const childCols: NodeListOf<HTMLElement> = dropdown!.querySelectorAll('.tier-2')

    if (!dropdown) {
      return
    }

    const dropdownCoords: ClientRect | DOMRect = dropdown.getBoundingClientRect()
    const navCoords: ClientRect | DOMRect = nav!.getBoundingClientRect()

    const bgCoords: BackgroundCoordinates = {
      width: dropdownCoords.width,
      height: dropdownCoords.height,
      left: dropdownCoords.left - navCoords.left,
      top: dropdownCoords.top - navCoords.top
    }

    const childColCoords: Dimension[] = [ ...childCols ].map((el: HTMLElement) => {
      return {
        height: el.offsetHeight,
        width: el.offsetWidth,
        area: el.offsetHeight * el.offsetWidth
      }
    })

    const tallestChild: Dimension = childColCoords.reduce((a: Dimension, b: Dimension) => {
      return a.height > b.height ? a : b
    })

    if (tallestChild.height < bgCoords.height) {
      shiftingBackground!.style.height = `${bgCoords.height}px`
    } else {
      dropdown.style.height = `${tallestChild.height + 50}px`
      shiftingBackground!.style.height = `${tallestChild.height + 50}px`
    }

    shiftingBackground!.style.width = `${bgCoords.width}px`

    shiftingBackground!.style.transform = `translate(${bgCoords.left}px, ${
      bgCoords.top
    }px)`
  }

  let movedIntoNav = false

  function toggleDelayStates(): void {
    movedIntoNav = true
    shiftingBackground!.classList.add('open')
    document.body.classList.add('nav-expanded')
  }

  function handleEnter(target: Element, toggleDelayStates: () => void): void {
    removeFlyOutExpandedAttributes()
    target.classList.add('open')
    const flyOutButton: HTMLButtonElement|null = target.querySelector('button')
    if (flyOutButton) {
      flyOutButton.setAttribute('aria-expanded', 'true')
    }
    if (movedIntoNav) {
      clearTimeouts()
      primaryNav.classList.add('hovered')
      primaryNav.classList.add('sibling-hovered')
      morphBackground(target)
    } else {
      linkOpacTimeout = window.setTimeout(setHoverClass, delay + delayAdditive)
      morphTimeout = window.setTimeout(() => {
        morphBackground(target)
      }, delay)
    }
    toggleDelayStates()
  }

  function handleLeave(): void {
    removeFlyOutExpandedAttributes()
    if (movedIntoNav) {
      clearFocusOnExit()
    }
    removeHoverClass()
    clearTimeouts()
    clearDropdown()
    movedIntoNav = false
  }

  function removeFlyOutExpandedAttributes(): void {
    for (const trigger of triggers) {
      trigger.classList.remove('open')
      const buttonToggle = trigger.querySelector('button')
      if (buttonToggle) {
        buttonToggle.setAttribute('aria-expanded', 'false')
      }
    }
  }

  function toggleFlyOutOnFocus(trigger){
    const flyOutButton = trigger.querySelector('button')
    const topLevelLink = trigger.querySelector('a.top-level-link')
    if (topLevelLink) {
      topLevelLink.addEventListener('focusout', (e: any) => {
        if (!isDescendant(primaryNav, e.relatedTarget)) {
          handleLeave()
        }
      })
      topLevelLink.addEventListener('focus', () => {
        removeFlyOutExpandedAttributes()
        removeHoverClass()
        clearTimeouts()
        clearDropdown()
      })
      topLevelLink.addEventListener('click', () => {
        removeFlyOutExpandedAttributes()
        removeHoverClass()
        clearTimeouts()
        clearDropdown()
      })
    }
    if (flyOutButton) {
      flyOutButton.addEventListener('focusout', (e: any) => {
        if (!isDescendant(primaryNav, e.relatedTarget)) {
          handleLeave()
        }
      })
      flyOutButton.addEventListener('focus',  (event) => {
        if (!trigger.classList.contains('open')) {
          removeFlyOutExpandedAttributes()
          removeHoverClass()
          clearTimeouts()
          clearDropdown()
        }
        event.preventDefault()
        return false
      })
      flyOutButton.addEventListener('click',  (event) => {
        if (trigger.classList.contains('open')) {
          trigger.classList.remove('open')
          flyOutButton.setAttribute('aria-expanded', 'false')
          removeHoverClass()
          clearTimeouts()
          clearDropdown()
        } else {
          handleEnter(trigger, toggleDelayStates)
          trigger.classList.add('open')
          flyOutButton.setAttribute('aria-expanded', 'true')
          const linkList: HTMLLinkElement|null = trigger.querySelector('ul.column-wrap')
          if (linkList) {
            linkList.tabIndex = 0
            linkList.focus()
            linkList.tabIndex = -1
          }
        }
        event.preventDefault()
        return false
      })

    }
  }

  const allPrimaryNavListItems = primaryNav.querySelectorAll('.tier-1')
  const allSecondaryNavLinks = primaryNav.querySelectorAll('.tier-2 a')

  function init(): void {
    for (const primaryNavListItem of allPrimaryNavListItems) {
      toggleFlyOutOnFocus(primaryNavListItem)
    }

    for (const secondaryNavItem of allSecondaryNavLinks) {
      secondaryNavItem.addEventListener('focusout', (e: any) => {
        if (!isDescendant(primaryNav, e.relatedTarget)) {
          handleLeave()
        }
      })
    }

    for (const trigger of triggers) {
      setGridHeights(trigger)

      if (isMobileSafari()) {
        trigger.addEventListener('touchstart', function (e) {
          e.stopPropagation()
          return
        })
      } else {
        trigger.addEventListener('mouseenter', function () {
          handleEnter(trigger, toggleDelayStates)
        })
      }
    }

    for (const antiTrigger of antiTriggers) {
      if (!isMobileSafari()) {
        antiTrigger.addEventListener('mouseenter', function () {
          handleLeave()
        })
      }
    }

    document.addEventListener(clearEventType, (e: any) => {
      if (!isDescendant(primaryNav, e.target)) {
        handleLeave()
      }
    })
  }

  return {
    init: init,
    handleLeave: handleLeave
  }
}
