<template>
  <div
    v-if="resourceIsUploadedFile || resource.resourceLink"
    class="resource-wrap"
  >
    <a
      class="resource"
      target="_blank"
      :href="fileUrl"
      :style="[
        { backgroundColor: backgroundAsOffwhite ? '#fff' : '#F8F8F8' },
        globalTheme.color
      ]"
    >
      <span
        class="file-type"
        :style="globalTheme.color">
        <app-inline-svg
          :style="globalTheme.color"
          :src="fileIcon"
          class="svg"
        />
        <span>
          {{ resource.title }}
        </span>
      </span>
      <span
        class="btn-svg"
        aria-describedby="leave-page-label">
        <font-awesome-icon
          :style="[globalTheme.colorWhite, globalTheme.backgroundColor]"
          aria-hidden="true"
          icon="external-link-alt"
        />
      </span>
    </a>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Resource } from '@/types'
  import defaultFileIcon from '@/assets/pdf.svg'
  import AppInlineSvg from '@/components/_global/AppInlineSvg.vue'
  import { snakeCase } from 'change-case'

  export default defineComponent({
    components: { AppInlineSvg },
    props: {
      resource: {
        type: Object as () => Resource,
        required: true
      },
      backgroundAsOffwhite: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        defaultFileIcon: defaultFileIcon,
        contentStackFile: this.resource.resource,
        resourceCustomIcon: this.resource.svgIcon
      }
    },
    computed: {
      resourceIsUploadedFile(): boolean {
        return !!this.contentStackFile  && !!this.contentStackFile.url
      },
      fileUrl(): string {
        const externalFile = this.resource.resourceLink as string | undefined
        if (!!this.contentStackFile  && !!this.contentStackFile.url) {
          return this.contentStackFile.url
        } else if (externalFile) {
          return externalFile
        } else {
          return ''
        }
      },
      fileExtension(): string {
        const fileToGet = this.contentStackFile
          ? this.contentStackFile.filename
          : this.resource.resourceLink
        return fileToGet ? (fileToGet.match(/\.[0-9a-z]+$/i) || [''])[0] : ''
      },
      fileIcon(): string {
        return this.resourceCustomIcon
          ? this.resourceCustomIcon.url
          : this.defaultFileIcon
      }
    },
    methods: {
      snakeCase
    }
  })
</script>
