<template>
  <section
    v-if="!preventFetchByCat"
    class="member-demo-module featured-cards featured-post-links">
    <div container>
      <div
        v-if="section.title"
        class="intro-content">
        <h2
          v-if="section.title"
          :style="globalTheme.colorText"
          class="_mbxs intro-title">
          {{ section.title }}
          <app-inline-svg
            v-if="section.titleSvgIcon"
            class="section-title-svg _mlxs"
            :style="globalTheme.fillText"
            :src="section.titleSvgIcon.url" />
        </h2>
      </div>
      <div grid="row wrap stretch">
        <div
          v-for="(post, i) in featuredCards"
          :key="i"
          :column="featuredCards.length > 3 ? 'xs-12 s-12 m-6 l-3' : 'xs-12 s-12 m-6 l-4'"
          class="_mbs _mts"
        >
          <featured-card :post="post"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackPost,
  } from '@/types'

  import {
    postsState,
    FeaturedPostsSection,
    SiftedPosts
  } from '@/mixins/FeaturedPostsModuleState'

  import {
    FeaturedCardProps,
    featuredCardFactory
  } from '@/components/sections/FeaturedPosts/FeaturedCard.vue'

  import FeaturedCard from '@/components/sections/FeaturedPosts/FeaturedCard.vue'

  interface FeaturedCardsSections extends FeaturedPostsSection {
    manualEntries: FeaturedCardProps[];
  }

  export default defineComponent({
    name: 'FeaturedCards' as string,
    components: { FeaturedCard },
    mixins: [postsState],
    props: {
      section: {
        type: Object as () => FeaturedCardsSections,
        required: true
      }
    },
    computed: {
      featuredCards(): FeaturedCardProps[] {
        const postsToDisplay = (this as any).postsToDisplay as SiftedPosts
        if (postsToDisplay.referencedPostList) {
          return (postsToDisplay.posts as ContentStackPost[]).map(refPost =>
            featuredCardFactory(refPost, this.$store.state.globals.globalLabels.readMore)
          )
        } else {
          return postsToDisplay.posts as FeaturedCardProps[]
        }
      }
    }
  })
</script>
