<template>
  <svg
    v-if="isAetna"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="chevron-down"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    class="svg-inline--fa fa-chevron-down"><path
      fill="currentColor"
      d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
      class=""></path></svg>
  <svg
    v-else
    aria-hidden="true"
    class="svg-inline--fa fa-caret-down fa-w-10"
    data-fa-i2svg=""
    data-icon="caret-down"
    data-prefix="fas"
    role="img"
    viewBox="0 0 320 512"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
    fill="currentColor"
  ></path></svg>
</template>
