<template>
  <template v-if="loaded">
    <div
      id="leave-page-label"
      aria-hidden="true"
      class="sr-only">
      Opens in new window
    </div>
    <app-header
      :key="$route.fullPath"
      :nav-items="mainNavItems"
    />
    <breadcrumbs :crumbs="crumbs"/>
    <router-view  :key="restOfRoute"/>
    <app-footer
      v-if="footerContent"
      :key="$route.fullPath"
      :footer-content="footerContent"
    />
  </template>
  <four-o-four v-else-if="failedToFetch" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { getGlobals, getCategoryList } from '@/ts/contentstack'
  import { mainNavigationFactory } from '@/ts/factories/main-navigation.factory'
  import { footerNavigationFactory } from '@/ts/factories/footer-navigation.factory'
  import FourOFour from '@/views/404.vue'
  import AppHeader from '@/components/organisms/Header.vue'
  import AppFooter from '@/components/organisms/Footer.vue'
  import Breadcrumbs from '@/components/molecules/BreadCrumbs/Breadcrumbs.vue'

  import {
    ContentStackPagePayload,
    MainNavigationItem,
    MainNavigationGroup,
    MainNavigationItemExternal,
    FooterNavigation
  } from '@/types'

  export default defineComponent({
    name: 'Globals',
    components: {
      FourOFour,
      AppHeader,
      AppFooter,
      Breadcrumbs
    },
    data() {
      return {
        loaded: false,
        failedToFetch: false,
        mainNavItems: [] as
          | (
            | MainNavigationItem
            | MainNavigationGroup
            | MainNavigationItemExternal
          )[]
          | [],
        loginLinks: [] as { href: string; title: string }[],
        loginLinksDropdownLabel: '' as string,
        footerContent: null as null | FooterNavigation,
        currentClientID: this.$route.params.clientID as string
      }
    },
    computed: {
      crumbs(): string[] {
        return this.$route.path === '/'
          ? ['/']
          : this.$route.fullPath
            .replace(/\/+$/, '')
            .split('/')
            .map(crumb => `/${crumb}`)
            .filter(crumb => !crumb.includes('/?'))
      },
      baseRoute(): string {
        return `/${this.$route.params.clientID}/${this.$route.params.lang}`
      },
      restOfRoute(): string {
        return `${
          this.$route.fullPath
            .replace(`/${this.$route.params.clientID}`, '')
            .replace(`/${this.$route.params.lang}`, '')
            .split('?')[0]
        }` || ('/' as string)
      },
    },
    methods: {
      convertFaSvgArias(): void {
        const faSvgs = this.$el.querySelectorAll('.svg-inline--fa')
        faSvgs.forEach(element => {
          element.setAttribute('role', 'presentation')
        })
      },
      setContentStackPayload(): ContentStackPagePayload {
        const currentLang = this.$route.params.lang as string
        const pageSlug =
          `${
            this.$route.fullPath
              .replace(`/${this.$route.params.clientID}`, '')
              .replace(`/${this.$route.params.lang}`, '')
              .split('?')[0]
          }` || ('/' as string)

        return {
          lang: currentLang,
          clientID: this.currentClientID,
          pageSlug: pageSlug,
          uid: ''
        }
      },
      setNavItems(globals): void {
        this.mainNavItems = mainNavigationFactory(globals.mainNavItems, this.currentClientID)
        this.loginLinksDropdownLabel = globals.loginDropdownLinks
          ? globals.loginDropdownLinks.loginLinksDropdownLabel
          : ''
        this.loginLinks = globals.loginDropdownLinks
          ? globals.loginDropdownLinks.loginLink
          : []
        this.footerContent = footerNavigationFactory(globals, this.currentClientID)
      }
    },
    async created() {
      const currentLang = this.$route.params.lang as string
      const currentClientID = this.$route.params.clientID as string
      const stackPayload = this.setContentStackPayload()

      const vuexGlobals = this.$store.state.globals
      const storedLocale = vuexGlobals ? vuexGlobals.locale : ''
      let globals
      const sameLang = storedLocale.substring(0, 2) === currentLang
      const sameClientID = vuexGlobals ? vuexGlobals.clientId !== currentClientID : false
      if (!vuexGlobals || !sameClientID || !sameLang) {
        globals = await getGlobals(stackPayload)
        const categoryList = await getCategoryList(stackPayload) || {}
        if (globals) {
          this.setNavItems(globals)
          this.$store.commit('setCurrentGlobals', globals)
          this.$store.commit('setCategoryList', Object.values(categoryList) || [])
          this.$store.commit('setPrimaryColor', globals.primaryColor)
          this.$store.commit('setSecondaryColor', globals.secondaryColor)
          if (globals.fontTheme && document.body) {
            document.body.classList.add(globals.fontTheme)
          }
          this.loaded = true
        } else {
          this.failedToFetch = true
        }
      } else {
        this.loaded = true
      }
    }
  })
</script>
