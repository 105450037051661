import { ColorOptionsInterface } from '@/types'

export const theme = {
  data() {
    return {
      primeColor: (this as any).$store.state.primaryColor || '#717272',
      secondColor: (this as any).$store.state.secondaryColor || '#000000'
    }
  },
  inject: ['colorOptions'],
  computed: {
    cascadedColorOptions(): ColorOptionsInterface | null {
      const sectionColorOptions = (this as any).section
        ? (this as any).section.colorOptions as ColorOptionsInterface
        : null
      const providedColorOptions = (this as any).colorOptions
        ? (this as any).colorOptions as ColorOptionsInterface
        : null
      return sectionColorOptions || providedColorOptions
    },
    useSecondaryColor(): boolean | null {
      return this.cascadedColorOptions
        ? (this.cascadedColorOptions as any).useSecondaryColor
        : false
    },
    colorOverride(): string | null {
      return this.cascadedColorOptions
        ? (this.cascadedColorOptions as any).colorOverride
        : null
    },
    primaryColor(): string {
      if ((this as any).colorOverride) {
        return (this as any).colorOverride
      } else {
        return (this as any).useSecondaryColor
          ? (this as any).$store.state.secondaryColor || '#000000'
          : (this as any).$store.state.primaryColor || '#717272'
      }
    },
    secondaryColor(): string {
      return (this as any).$store.state.secondaryColor || '#000000'
    },
    globalTheme(): any {
      return {
        color: {
          color: `${(this as any).primaryColor} !important`
        },
        colorSoft: {
          color: `${(this as any).primaryColor}`
        },
        colorForSure: {
          color: `${(this as any).primaryColor} !important`
        },
        colorNoCheck: {
          color: `${(this as any).primaryColor} !important`
        },
        colorWhite: {
          color: '#fff !important'
        },
        colorError: {
          color: 'firebrick'
        },
        colorSuccess: {
          color: 'steelblue'
        },
        colorBang: {
          color: `${(this as any).secondaryColor} !important`
        },
        colorSecondary: {
          color: `${(this as any).secondaryColor} !important`
        },
        backgroundColor: {
          backgroundColor: (this as any).primaryColor
        },
        backgroundColorWhite: {
          backgroundColor: 'white'
        },
        backgroundColorTransparent: {
          backgroundColor: 'transparent'
        },
        backgroundColorSecondary: {
          backgroundColor: (this as any).secondaryColor
        },
        backgroundColorError: {
          backgroundColor: 'firebrick'
        },
        backgroundColorSuccess: {
          backgroundColor: 'steelblue'
        },
        borderColor: {
          borderColor: (this as any).primaryColor
        },
        fill: {
          fill: `${(this as any).primaryColor} !important`,
          color: `${(this as any).primaryColor} !important`
        },
        fillSoft: {
          fill: `${(this as any).primaryColor}`,
          color: `${(this as any).primaryColor}`
        },
        fillBlack: {
          fill: '#bfbfbf !important',
          color: '#bfbfbf !important'
        },
        fillText: {
          fill: '#717272 !important',
          color: '#717272 !important'
        },
        fillTextSoft: {
          fill: '#717272',
          color: '#717272'
        },
        fillWhite: {
          fill: '#ffffff !important',
          color: '#ffffff !important'
        },
        fillSecondary: {
          fill: `${(this as any).secondaryColor} !important`,
          color: `${(this as any).secondaryColor} !important`
        },
        button: {
          backgroundColor: (this as any).primaryColor,
          borderColor: (this as any).primaryColor,
          color: 'white'
        },
        buttonSecondary: {
          backgroundColor: (this as any).secondaryColor,
          borderColor: (this as any).secondaryColor,
          color: 'white'
        },
        buttonWhite: {
          color: (this as any).primaryColor,
          backgroundColor: '#fff'
        },
        buttonWhiteBordered: {
          color: (this as any).primaryColor,
          borderColor: (this as any).primaryColor,
          backgroundColor: 'transparent'
        },
        buttonSecondaryWhiteBordered: {
          color: (this as any).secondaryColor,
          borderColor: (this as any).secondaryColor,
          backgroundColor: 'transparent'
        },
        colorText: {
          color: (this as any).colorTextOverride || '#717272'
        },
        colorBlack: {
          color: '#717272'
        },
        colorBlackForSure: {
          color: '#717272'
        }
      }
    }
  }
}
