<template>
  <app-inline-svg
    v-if="postIcon"
    class="post-type-icon"
    :src="postIcon.url" />
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { camelCase } from 'change-case'
  import { PostType } from '@/types'
  export default defineComponent({
    name: 'PostTypeIcon',
    props: {
      postType: {
        type: Object as () => PostType,
        required: true
      }
    },
    computed: {
      postIcon(): string {
        const hasPostType = this.postType && this.postType.postType
        const postType = hasPostType ? camelCase(this.postType.postType.toLowerCase()) : 'article'
        return this.$store.state.globals.postTypeSvgIcons
          ? this.$store.state.globals.postTypeSvgIcons[postType]
          : null
      }
    }
  })
</script>
