<template>
  <form
    v-if="renderSearchForm"
    class="searchbar"
    @submit.prevent="submitSearch">
    <label
      :for="uid"
      class="visuallyhidden">{{labels.search}}</label>
    <input
      :id="uid"
      v-model="searchQuery"
      type="text"
      :name="uid"
      :placeholder="labels.searchPlaceholder" />
    <button
      type="submit"
      :style="globalTheme.buttonSecondary"
      class="btn">
      {{labels.search}}
    </button>
  </form>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackCategoryPage, MicrositeReferences } from '@/types'
  import { filterPagetypeByReferences } from '@/mixins/FeaturedPostsModuleState'
  export default defineComponent({
    name: 'searchForm' as string,
    props: {
      uid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchQuery: '',
        labels: this.$store.state.globals.globalLabels,
        searchPageList:
          this.$store.state.globals.searchPage ||
          ([] as ContentStackCategoryPage[]),
        clientID: this.$route.params.clientID as string,
        selectedLanguage: this.$route.params.lang as string
      }
    },
    computed: {
      searchPage(): ContentStackCategoryPage|null {
        return this.searchPageList[0]
      },
      renderSearchForm(): boolean {
        const searchPage = this.searchPage
        if (searchPage) {
          return (
            searchPage &&
            filterPagetypeByReferences(
              searchPage.micrositeReferences as MicrositeReferences,
              this.clientID as string
            )
          )
        } else {
          return false
        }
      },
      localizedRoute(): string {
        if (!this.searchPage) return '/'
        let conpmutedUrl = this.searchPage.url as string || ''
        conpmutedUrl = conpmutedUrl.startsWith('/') ? conpmutedUrl.substr(1) : conpmutedUrl
        return `/${this.clientID}/${this.selectedLanguage}/${conpmutedUrl}`
      }
    },
    methods: {
      submitSearch(): void {
        const searchPage = this.searchPage
        if (searchPage) {
          const samePage = this.localizedRoute === this.$route.path
          this.$router.push({ path: this.localizedRoute, query: { s: this.searchQuery }})
            .then(() => {
              if (samePage) {
                window.location.reload()
              } else {
                this.searchQuery = ''
              }
            })
        }
      }
    },
  })
</script>
