<template>
  <td>
    <app-inline-svg
      v-if="cell.icon && cell.icon.url"
      class="title-svg"
      :src="cell.icon.url"
      :style="globalTheme.fill"
    />
    <rich-text
      v-if="cell.content"
      class="not-themed"
      :theme-color-override="themeColorOverride"
      :html="cell.content" />
    <div
      v-if="cell.buttonLink && cell.buttonLink.href"
      class="cta-wrap center-cta"
      :class="!!cell.icon || !!cell.content ? '_mts' : null">
      <app-button-link
        :button="cell.buttonLink"
        :style="globalTheme.button"
      />
    </div>
  </td>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackFile, ButtonLink } from '@/types'

  export interface CellProps {
    content?:string,
    icon?: ContentStackFile,
    buttonLink?: ButtonLink
  }

  export default defineComponent({
    name: 'Cell',
    props: {
      cell: {
        required: true,
        type: Object as () => CellProps
      },
      themeColorOverride: {
        required: false,
        type: Array as ()=> Record<string, unknown>[]
      }
    },
    computed: {
      colorOverride(): string | undefined | unknown {
        const colorOverrideObject =
          this.themeColorOverride &&
          this.themeColorOverride.length
            ? this.themeColorOverride[0]
            : {}
        return colorOverrideObject.colorHex as string | undefined
      }
    }
  })
</script>
