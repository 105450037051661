<template>
    
  <div
    class="feature-listings-and-image"
    grid="row wrap center"
  >
    <div column="xs-12 s-12 m-12 l-12">
      <div grid="row wrap stretch">
        <div
          v-for="(callout, i) in section.callouts"
          :key="i"
          :column="section.calloutColumnCount === '2' ? 'xs-12 s-6 m-6' : 'xs-12 s-6 m-6 l-4'"
        >
          <div
            class="feature-listing"
          >
            <app-inline-svg
              :src="callout.iconPath" 
              :style="globalTheme.fill"/>
            <div class="content">
              <h3
                v-if="callout.title"
                :style="globalTheme.color"
                class="text-center h4"
              >{{callout.title}}</h3>
              <rich-text
                v-if="callout.content"
                class="callout-content-wrap"
                :html="callout.content"></rich-text>
              <app-button-link
                v-if="callout.buttonCta"
                :button="callout.buttonCta"
                :style="globalTheme.button"
                class="_mts tablet-up"
              />
            </div>
          </div>
          <app-button-link
            v-if="callout.buttonCta"
            :button="callout.buttonCta"
            :style="globalTheme.button"
            class="mobile _mbs"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { SmallCallouts } from '@/types'
  export default defineComponent({
    name: 'small-callouts-content',
    props: {
      section: {
        required: true,
        type: Object as () => SmallCallouts
      }
    }
  })
</script>
