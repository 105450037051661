<template>
  <li
    v-if="!hideLink"
    class="search-page-link _mts _ps">
    <a :href="link.url">
      <div class="image-wrap">
        <post-type-icon
          v-if="searchPageLink.postType && showIcon"
          :post-type="searchPageLink.postType"
          :style="[globalTheme.fillWhite, globalTheme.backgroundColor]"
        />
        <app-image
          v-if="featuredImage"
          :image="featuredImage"
          :alt="featuredAlt"
          @loaded="revealIcon"
        />
      </div>
      <div class="content">
        <h5
          v-if="searchPageLink.pageLabel"
          class="page-type _mbxs">
          {{searchPageLink.pageLabel}}
        </h5>
        <h5
          v-else-if="searchPageLink.postType"
          class="page-type _mbxs">{{ searchPageLink.postType.postType }}</h5>
        <p class="page-title _m0 _mbs">{{ link.label }}</p>
        <p
          class="read-more-link"
          :style="globalTheme.color"
        >{{labels.readMore}}
        </p>
      </div>
    </a>
  </li>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  import {
    ContentStackFile,
    PostType,
    MicrositeReferences,
    MainNavigationItem,
  } from '@/types'

  export interface SearchPageLinkProps {
    title: string
    url: string
    contentTypeUid?: string
    micrositeReferences?: MicrositeReferences
    featuredImage?: ContentStackFile
    featuredImageAlt?: string
    featuredThumbnail?: ContentStackFile
    featuredThumbnailAlt?: string
    fullDescription?: string
    postType?: PostType
    internalLink?: MainNavigationItem | undefined
    pageType?: string
    pageLabel?: string
  }

  import PostTypeIcon from '../FeaturedPosts/PostTypeIcon.vue'

  export default defineComponent({
    name: 'SearchPageLink',
    components: { PostTypeIcon },
    props: {
      searchPageLink: {
        type: Object as () => SearchPageLinkProps,
        required: true,
      },
    },
    data() {
      return {
        showIcon: false,
        link: this.searchPageLink.internalLink,
        featuredImage:
          this.searchPageLink.featuredThumbnail ||
          this.searchPageLink.featuredImage,
        featuredAlt:
          this.searchPageLink.featuredThumbnailAlt ||
          this.searchPageLink.featuredImageAlt,
        labels: this.$store.state.globals.globalLabels,
      }
    },
    methods: {
      revealIcon() {
        this.showIcon = true
      }
    }
  })
</script>
