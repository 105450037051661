<template>
  <all-posts
    :category-url="pageSlug"
    :section="section"/>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate } from '@/types'
  import AllPosts from '@/components/sections/AllPosts.vue'

  export default defineComponent({
    name: 'PlaceBlogPostCards',
    components: { AllPosts },
    props: {
      section: {
        type: Object as () => Boilerplate,
        required: false
      },
    },
    data()  {
      return {
        pageSlug:
          `${
            this.$route.fullPath
              .replace(`/${this.$route.params.clientID}`, '')
              .replace(`/${this.$route.params.lang}`, '')
              .replace(`/category`, '')
              .split('?')[0]
          }` || ('/' as string),
      }
    },
    provide() {
      return {
        colorOptions: this.section ? this.section.colorOptions : null
      }
    }
  })
</script>
