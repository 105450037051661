<template>
  <localized-router-link
    v-if="post.link"
    class="mixed-feature"
    :to="post.link.href"
    :style="globalTheme.color"
    :class="{first: first}"
  >
    <div
      v-if="post.featuredImage"
      class="image-wrap">
      <app-image
        :image="post.featuredImage"
        aria-hidden/>
    </div>
    <div
      class="content _ps"
      :class="{ 'no-image': !post.featuredImage }">
      <p
        v-if="post.link.title"
        class="title">{{ post.link.title }}</p>
      <p
        v-if="post.blurb"
        class="read-more"
        :style="globalTheme.colorText">
        {{post.blurb}}
      </p>
    </div>
  </localized-router-link>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    ContentStackFile,
    ContentStackLink,
    ContentStackPost
  } from '@/types'

  export interface MixedFeatureProps {
    blurb?: string;
    featuredImage?: ContentStackFile;
    link: ContentStackLink;
  }

  import { setPostLinkCardBlurb } from '@/ts/utility.methods'

  export function mixedFeatureFactory(post: ContentStackPost): MixedFeatureProps {
    return {
      blurb: setPostLinkCardBlurb(post),
      featuredImage: post.featuredThumbnail || post.featuredImage,
      link: {
        title: post.title,
        href: `/post${post.url}`
      }
    }
  }

  export default defineComponent({
    name: 'MixedFeature',
    props: {
      first: {
        type: Boolean,
        default: false
      },
      post: {
        type: Object as () => MixedFeatureProps,
        required: false
      }
    }
  })
</script>
