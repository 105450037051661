<template>
  <iframe
    v-if="section.iframeContentUrl"
    class="iframe-content"
    :src="section.iframeContentUrl"
    @load="loadIframe"
  />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Boilerplate, ContentStackFile } from '@/types'
  interface IframeContentProps extends Boilerplate {
    iframeContent: ContentStackFile
    iframeHeights: {
      desktop: number
      mobile: number
      tablet?: number
    }
  }

  export default defineComponent({
    name: 'IframeContent' as string,
    props: {
      section: {
        type: Object as () => IframeContentProps,
        required: true,
      },
    },
    data() {
      return {
        loaded: false
      }
    },
    methods: {
      setIframeHeight(): void {
        const viewWidth = this.$el.offsetWidth
        const iframeHeights = this.section.iframeHeights
        if (viewWidth > 959) {
          this.$el.height = iframeHeights.desktop
        } else if (viewWidth > 767) {
          this.$el.height = iframeHeights.tablet ? iframeHeights.tablet : iframeHeights.mobile
        } else {
          this.$el.height = iframeHeights.mobile
        }
      },
      loadIframe(): void {
        this.setIframeHeight()
        this.loaded = true
      }
    }
  })
</script>
