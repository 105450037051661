<template>
  <section
    v-if="section.featuredContent.length"
    class="member-demo-module featured-copy multi"
  >
    <div container>
      <div
        v-if="section.title || section.content"
        class="intro-content _mbm">
        <h2
          v-if="section.title"
          :style="globalTheme.color"
          class="section-header h2"
        >
          {{ section.title }}
        </h2>
        <div
          v-if="section.content"
          class="copy-wrap">
          <rich-text :html="section.content"></rich-text>
        </div>
      </div>
      <div grid="row wrap justify-center stretch">
        <div 
          v-for="(smallFeature, i) in section.featuredContent"
          :key="i"
          :column="smallFeatureColumnCount"
          class="featured-copy-wrap _mbl">
          <app-feature
            :small-feature="smallFeature"
            :background-as-offwhite="section.colorOptions.backgroundAsOffwhite"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import {
    Boilerplate,
    IndividualFeature
  } from '@/types'

  export interface SmallFeatureProps extends Boilerplate {
    featuredContent: IndividualFeature[];
  }

  import { resourceFactory } from '@/ts/factories/resource.factory'

  import  AppFeature from './SmallFeature/Feature.vue'

  export default defineComponent({
    name: 'SmallFeature',
    components: {
      AppFeature
    },
    props: {
      section: {
        required: true,
        type: Object as () => SmallFeatureProps
      }
    },
    computed: {
      smallFeatureColumnCount() {
        return this.section.featuredContent.length === 1
          ? 'xs-12 s-12 m-6 l-8'
          : 'xs-12 s-12 m-4'
      }
    },
    methods: {
      resourceFactory
    },
    provide() {
      return {
        colorOptions: this.section.colorOptions
      }
    }
  })
</script>
