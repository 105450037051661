<template>
  <section 
    v-if="!preventFetchByCat"
    class="member-demo-module article-cards featured-post-links">
    <div container>
      
      <div
        v-if="section.title || showCategorySelect"
        grid="row wrap"
        class="intro-content _mbxs">
        <div :column="showCategorySelect ? 'xs-12 s-12 m-9' : 'xs-12 s-12'">
          <h2
            v-if="section.title"
            :style="globalTheme.colorText"
            class="intro-title">
            {{ section.title }}
            <app-inline-svg
              v-if="section.titleSvgIcon"
              class="section-title-svg _mlxs"
              :style="globalTheme.fillText"
              :src="section.titleSvgIcon.url" />
          </h2>

        </div>
        <div
          v-if="showCategorySelect"
          column="xs-12 s-12 m-3">
          <category-selector
            :current-category="referencedCategory"
            @newCategorySelected="updateSelectedCategory"/>
          
        </div>
      </div>
      <app-loader v-if="fetching"/>
      <p
        v-else-if="showCategorySelect && !articleCards.length"
        class="_mtl _text-center"> 
        {{noPostsMessage}} 
      </p>
      <div
        v-else
        grid="row wrap stretch">
        <div
          v-for="(post, i) in articleCards"
          :key="i"
          column="xs-12 s-12 m-6 l-3"
          class="_mbs _mts"
        >
          <article-card :post="post"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ContentStackPost } from '@/types'

  import {
    postsState,
    FeaturedPostsSection,
    SiftedPosts,
  } from '@/mixins/FeaturedPostsModuleState'

  import {
    ArticleCardProps,
    articleCardFactory,
  } from '@/components/sections/FeaturedPosts/ArticleCard.vue'

  import ArticleCard from '@/components/sections/FeaturedPosts/ArticleCard.vue'

  interface ArticleCardsSections extends FeaturedPostsSection {
    manualEntries: ArticleCardProps[]
  }

  export default defineComponent({
    name: 'ArticleCards' as string,
    components: { ArticleCard },
    mixins: [postsState],
    props: {
      section: {
        type: Object as () => ArticleCardsSections,
        required: true,
      },
    },
    computed: {
      articleCards(): ArticleCardProps[] {
        const postsToDisplay = (this as any).postsToDisplay as SiftedPosts
        if (postsToDisplay.referencedPostList) {
          return (postsToDisplay.posts as ContentStackPost[]).map((refPost) =>
            articleCardFactory(refPost)
          )
        } else {
          return postsToDisplay.posts as ArticleCardProps[]
        }
      },
    },
  })
</script>
