import { createRouter, createWebHashHistory,createWebHistory, RouteRecordRaw } from 'vue-router'
import { defaultLocale } from '@/ts/i18n'
import Globals from '../Globals.vue'
import Page from '../views/Page.vue'
import Archive from '../views/Archive.vue'
import Post from '../views/Post.vue'

const isStagePages = import.meta.env.VITE_STAGEPAGES === 'true'

function setSlugLength(totalSlugs: number): any {
  const routes = [] as any[]
  let path = '/:clientID/:lang/:slug'
  for (let i = 1; i < totalSlugs; i++) {
    path += `/:slug_${i}`
    const route = {
      path: path,
      name: `subroute_${i}`,
      component: Page,
      meta: {
        guest: true
      }
    }
    routes.push(route)
  }
  return routes
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:clientID/',
    redirect: (to) => {
      return { path: `${to.fullPath}/${defaultLocale}/` }
    }
  },
  {
    path: '/:clientID/:lang',
    name: 'GlobalsWrapper',
    component: Globals,
    children: [
      {
        path: '/:clientID/:lang/',
        name: 'Homepage',
        component: Page,
      },
      {
        path: '/:clientID/:lang/:slug',
        name: 'Page',
        component: Page,
      },
      {
        path: '/:clientID/:lang/category/:category',
        name: 'ArchivePage',
        component: Archive,
      },
      {
        path: '/:clientID/:lang/post/:post',
        name: 'Post',
        component: Post,
      },
      ...setSlugLength(9)
    ]
  },
]

const router = createRouter({
  history: !isStagePages ? createWebHistory() : createWebHashHistory(),
  routes,
})

export default router
