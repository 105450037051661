import { DigitalDataObject, AdobePageObject } from '@/types'

const isStage: boolean = import.meta.env.VITE_STAGING === 'true'
export const analyticsUrl = import.meta.env.VITE_ANALYTICS_URL

export function updateAdobePageInfo(pageInfoPayload: AdobePageObject): void {
    window.digitalData.page = pageInfoPayload
}

export function updateAdobeClientID(clientID: string): void {
    window.digitalData.clientID = clientID
}

export function addAdobeDigitalData(): void {
    window.digitalData = {
      version: '2.0',
      page: {
        pageInfo: {
          pageName: '',
          sysEnv: 'browser'
        }
      },
      clientID: '',
      event: [],
      trackPageView: function(pageName) {
        window.digitalData.page.pageInfo.pageName = pageName
        window.digitalData.event.push({
          eventName: pageName,
          type: 'Page View'
        })
        window.digitalData._sendToAnalyticsServer('event-page-view')
      },
      trackEvent: function(eventName) {
        window.digitalData.event.push({
          eventName: eventName,
          type: 'Event'
        })
        window.digitalData._sendToAnalyticsServer(eventName)
      },
      _sendToAnalyticsServer: function(event, parameters) {
        if (isStage) return
        const interval = setInterval(function() {
          if (typeof window['_satellite'] === 'undefined') {
            return
          } else {
            window['_satellite'].track(event, parameters)
            clearInterval(interval)
          }
        }, 100)
      }
    } as DigitalDataObject
  }
